import { createApiRef } from '@backstage/core-plugin-api';
import { AxiosInstance } from 'axios';
import { AxiosInstanceProviderApi } from './axiosInstanceApi';

export interface RoadmapApi {
  /* PageList Related Apis*/
  getRoadmapFile: () => Promise<any>;
}

export const roadmapApiRef = createApiRef<RoadmapApi>({
  id: 'cbre.devx.api.roadmap',
});


export class RoadmapImpl implements RoadmapApi {
  private readonly axiosInstancePromise: Promise<AxiosInstance>;

  constructor(axiosInstanceProviderApi: AxiosInstanceProviderApi) {
    this.axiosInstancePromise = axiosInstanceProviderApi.getInstance();
  }

  async getRoadmapFile() {
    const instance = await this.axiosInstancePromise;
    try {
      return await instance
        .get('service/discovery/filedetails/latest')
        .then(res => res?.data);
    } catch (error) {
      console.error('Error ', error);
      return;
    }
  }
}
