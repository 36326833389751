import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CardTab, TabbedCard } from '@backstage/core-components';
import { RecentlyPublishedTable } from './RecentlyPublishedTable';
import {
  RECENTLY_PUBLISHED,
  IS_SALESFORCE_ENABLE,
} from '../../../utils/constant';
import SyncIcon from '@material-ui/icons/Sync';
import { useCustomStyles } from '../../../common/Cards/CardStyles';
import { useApi } from '@backstage/core-plugin-api';
import { dashboardApiRef } from '../../../../apis/dashboardApi';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../../apis/notificationApi';

export const RecentlyPublished = () => {
  const classes = useCustomStyles();
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState<string | number>('All');
  const [allData, setAllData] = useState<any>([]);
  const [codeData, setCodeData] = useState<any>([]);
  const [apiData, setApiData] = useState<any>([]);
  const [iacData, setIacData] = useState<any>([]);
  const [aiMLData, setAiMLData] = useState<any>([]);
  const [extensionData, setExtensionData] = useState<any>([]);
  const [salesforceData, setSalesforceData] = useState<any>([]);
  const [patternData, setPatternData] = useState<any>([]);
  const [Refresh, setRefresh] = useState<any>(false);
  const dashboardApi = useApi(dashboardApiRef);
  const notificationApi: NotificationApi = useApi(notificationApiRef);

  useEffect(() => {
    localStorage.setItem('isReview', 'false');
    if (selectedTab === 'Code') {
      if (!codeData.length) {
        dashboardApi
          .getDataForRecentlyPublished(
            RECENTLY_PUBLISHED.RECENTLY_PUBLISHED_CODE,
          )
          .then((res: any) => setCodeData(res.data))
          .catch((error: any) => {
            notificationApi.sendNotification({
              message: `Error occurred - ${error?.message}`,
              disapperAfterMs: 2500,
              severity: 'error',
            });
          });
      }
    } else if (selectedTab === 'API') {
      if (!apiData.length) {
        dashboardApi
          .getDataForRecentlyPublished(
            RECENTLY_PUBLISHED.RECENTLY_PUBLISHED_API,
          )
          .then((res: any) => setApiData(res.data))
          .catch((error: any) => {
            notificationApi.sendNotification({
              message: `Error occurred - ${error?.message}`,
              disapperAfterMs: 2500,
              severity: 'error',
            });
          });
      }
    } else if (selectedTab === 'IaC') {
      if (!iacData.length) {
        dashboardApi
          .getDataForRecentlyPublished(
            RECENTLY_PUBLISHED.RECENTLY_PUBLISHED_IAC,
          )
          .then((res: any) => setIacData(res.data))
          .catch((error: any) => {
            notificationApi.sendNotification({
              message: `Error occurred - ${error?.message}`,
              disapperAfterMs: 2500,
              severity: 'error',
            });
          });
      }
    } else if (selectedTab === 'salesforce') {
      if (!salesforceData.length) {
        dashboardApi
          .getDataForRecentlyPublished(
            RECENTLY_PUBLISHED.RECENTLY_PUBLISHED_SALESFORCE,
          )
          .then((res: any) => setSalesforceData(res.data))
          .catch((error: any) => {
            notificationApi.sendNotification({
              message: `Error occurred - ${error?.message}`,
              disapperAfterMs: 2500,
              severity: 'error',
            });
          });
      }
    } else if (selectedTab === 'aiML') {
      if (!aiMLData.length) {
        dashboardApi
          .getDataForRecentlyPublished(
            RECENTLY_PUBLISHED.RECENTLY_PUBLISHED_AIML,
          )
          .then((res: any) => setAiMLData(res.data))
          .catch((error: any) => {
            notificationApi.sendNotification({
              message: `Error occurred - ${error?.message}`,
              disapperAfterMs: 2500,
              severity: 'error',
            });
          });
      }
    } else if (selectedTab === 'extension') {
      if (!aiMLData.length) {
        dashboardApi
          .getDataForRecentlyPublished(
            RECENTLY_PUBLISHED.RECENTLY_PUBLISHED_EXTENSION,
          )
          .then((res: any) => setExtensionData(res.data))
          .catch((error: any) => {
            notificationApi.sendNotification({
              message: `Error occurred - ${error?.message}`,
              disapperAfterMs: 2500,
              severity: 'error',
            });
          });
      }
    } else if (selectedTab === 'pattern') {
      if (!aiMLData.length) {
        dashboardApi
          .getDataForRecentlyPublished(
            RECENTLY_PUBLISHED.RECENTLY_PUBLISHED_PATTERN,
          )
          .then((res: any) => setPatternData(res.data))
          .catch((error: any) => {
            notificationApi.sendNotification({
              message: `Error occurred - ${error?.message}`,
              disapperAfterMs: 2500,
              severity: 'error',
            });
          });
      }
    } else {
      if (!allData.length || Refresh) {
        setRefresh(false);
        dashboardApi
          .getDataForRecentlyPublished(
            RECENTLY_PUBLISHED.RECENTLY_PUBLISHED_ALL,
          )
          .then((res: any) => setAllData(res.data))
          .catch((error: any) => {
            notificationApi.sendNotification({
              message: `Error occurred - ${error?.message}`,
              disapperAfterMs: 2500,
              severity: 'error',
            });
          });
      }
    }
  }, [selectedTab, Refresh]);

  const handleChange = (_ev: any, newSelectedTab: string | number) =>
    setSelectedTab(newSelectedTab);

  const refreshClick = () => setRefresh(true);

  // TODO: Add in filter base url on all-asset and enable See more for those options
  return (
    <TabbedCard
      title="Most Recently Published"
      value={selectedTab}
      onChange={handleChange}
    >
      <button className="refreshButton" onClick={refreshClick}>
        <SyncIcon />
      </button>

      <CardTab label="All" value="All" color="primary">
        <RecentlyPublishedTable data={allData} />
      </CardTab>
      <CardTab label="Code/Lib" value="Code" color="primary">
        <RecentlyPublishedTable data={codeData} />
      </CardTab>
      <CardTab label="API" value="API" color="primary">
        <RecentlyPublishedTable data={apiData} />
      </CardTab>
      <CardTab label="IaC" value="IaC" color="primary">
        <RecentlyPublishedTable data={iacData} />
      </CardTab>
      {/* {IS_SALESFORCE_ENABLE && (
        <CardTab label="Salesforce" value="salesforce" color="primary">
          <RecentlyPublishedTable data={salesforceData} />
        </CardTab>
      )} */}
      <CardTab label="AI Accelerators" value="aiML" color="primary">
        <RecentlyPublishedTable data={aiMLData} />
      </CardTab>
      <CardTab label="Extensions" value="extension" color="primary">
        <RecentlyPublishedTable data={extensionData}/>
      </CardTab>
      <CardTab label="Arch. Patterns" value="pattern" color="primary">
        <RecentlyPublishedTable data={patternData} />
      </CardTab>

      <CardTab
        label="See more ➔"
        value="seeMore"
        disableFocusRipple
        onClick={() => {
          switch (selectedTab) {
            case 'All':
              navigate('/catalog/all-assets');
              break;
            case 'Code':
              navigate('/code-library');
              break;
            case 'API':
              navigate('/API');
              break;
            default:
              break;
          }
        }}
        onMouseDown={() => {
          switch (selectedTab) {
            case 'All':
              navigate('/catalog/all-assets');
              break;
            case 'Code':
              navigate('/code-library');
              break;
            case 'API':
              navigate('/API');
              break;
            default:
              break;
          }
        }}
        className={classes.seeMorePublishedButton}
        style={{
          ...(selectedTab === 'All' || selectedTab === 'seeMore' || selectedTab === 'Code' || selectedTab === 'API'
            ? { visibility: 'visible' }
            : { visibility: 'hidden' }),
        }}
      >
        <></>
      </CardTab>
    </TabbedCard>
  );
};
