import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { performanceTestStyles } from './performaceTestStyles';
import { commonStyles } from '../../../styles/common';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...commonStyles(theme),
    /* Styles for All Projects Page */
    assetTypeDropdown: {
      width: '265px',
    },
    desktopSearch: {
      flexGrow: 1,
      '& input': {
        padding: '10.5px 14px',
      },
    },
    layoutPage: {
      [theme.breakpoints.down('sm')]: {
        padding: '0px',
      },
    },
    header: {
      marginBottom: '20px',
      alignItems: 'center',
    },
    filterProject: {
      marginRight: `${theme.spacing(3.2)}px`,
      fontSize: `${theme.spacing(1.5)}px`,
      color: theme.palette.text.secondary,
    },
    filterProjectDropDown: {
      fontStyle: 'normal',
      fontWeight: 'bold',
    },
    statusDropdown: {
      padding: `${theme.spacing(1.375)}px ${theme.spacing(1.875)}px`,
      background: theme.palette.background.paper,
    },
    body: {
      display: 'flex',
      alignItems: 'left',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '16px',
      color: theme.palette.text.secondary,
      lineHeight: '20px',
      textAlign: 'left',
    },

    filterChipButton: {
      //   margin: 'auto 0',
    },

    tagSelected: {
      backgroundColor: theme.palette.primary.main,
      '&:hover, &:focus': {
        backgroundColor: theme.palette.primary.main,
      },
      color: theme.palette.text.contrast,
    },
    /* End of - Styles for All Projects Page */

    /* Styles for Edit Details Page */
    editPage: {
      margin: `.5px ${theme.spacing(0)}px`,
      marginBottom: theme.spacing(3),
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    },

    headerText: {
      fontSize: '24px',
    },
    bodyText: { fontSize: '20px' },

    buttonGroup: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    editPaper: {
      padding: theme.spacing(2),
      backgroundColor: theme.palette.background.default,
    },
    saveButton: { marginLeft: theme.spacing(2) },

    /* End of styles for edit Details Page */

    /* Styles for Overview Page */
    overwiewCards: {
      margin: `.5px ${theme.spacing(0)}px`,
      marginBottom: theme.spacing(2),
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    },
    applicationDescription: {
      color: theme.palette.text.tertiary,
    },
    apmInfoLabel: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '18px',
      color: theme.palette.text.muted,
      flexDirection: 'row',
    },
    detailsBody: {
      color: theme.palette.text.muted,
    },

    toolGrid: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    /* End of styles for Overview  Page */

    /* Styles for ADD Tool Dialog */
    actionButton: {
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: '4px',
      marginLeft: theme.spacing(2),
    },
    accordionSummary: {
      background: theme.palette.background.tertiary,
    },
    /* End of Styles for ADD Tool Dialog */

    /* All Card styles */
    /* Styles for ProjectCard */

    projectCard: {
      position: 'relative',
    },
    projectCardActionArea: {
      backgroundColor: theme.palette.background.paper,
      display: 'flex',
      boxShadow:
        '0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)',
      height: '210px',
      alignItems: 'flex-start',
    },

    projectContent: {
      display: 'flex',
      height: '100%',
      flexDirection: 'column',
    },

    cardTitle: {
      wordBreak: 'break-word',
      '-webkit-box-orient': 'vertical',
      '-webkit-line-clamp': '2',
      display: '-webkit-box',
      overflow: 'hidden',
      width: '80%',
      fontSize: `${theme.spacing(2)}px`,
      marginBottom: '0px',
    },

    bookmarkButton: {
      position: 'absolute',
      top: theme.spacing(1),
      zIndex: 1,
      right: 0,
    },
    bookmarkSvg: {
      transform: 'scale(1.33)',
    },

    cardDescription: {
      flexGrow: 1,
      color: theme.palette.text.tertiary,
      marginTop: `${theme.spacing(1)}px`,

      '& p': {
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': '4',
        wordBreak: 'break-word',
        display: '-webkit-box',
        overflow: 'hidden',
      },
    },
    chipColumn: {
      justifyContent: 'flex-end',
    },
    flexStart: {
      justifyContent: 'flex-start',
      gap: `${theme.spacing(3)}px`,
      marginTop: `${theme.spacing(2)}px`,
      marginBottom: `${theme.spacing(2)}px`,
    },
    scopeChip: {
      margin: '0px',
      textTransform: 'uppercase',
    },
    sustainChip: {
      color: '#fff',
      backgroundColor: theme.palette.colors.darkblue,
    },
    investChip: {
      color: '#fff',
      backgroundColor: theme.palette.colors.greencyan,
    },
    apmLabel: {
      color: theme.palette.text.tertiary,
      fontSize: `${theme.spacing(1.5)}px`,
      lineHeight: `${theme.spacing(2)}px`,
    },
    apmValue: {
      color: theme.palette.text.secondary,
    },
    apmValueHighlighted: {
      color: theme.palette.text.primary,
      '&:hover': {
        cursor: 'default',
      },
    },
    /* Styles for AvailableToolCard */
    availableCardSelected: {
      border: '1px solid green',
    },
    availableCardNotSelected: {
      border: 'none',
    },

    /* Styles for ActivityTableCard */
    activityCard: {
      margin: `.5px ${theme.spacing(0)}px`,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    },

    activityChip: {
      color: theme.palette.common.white,
      textTransform: 'uppercase',
      marginBottom: '0',
    },
    serviceChip: {
      backgroundColor: theme.palette.colors.greencyan,
    },

    toolChip: {
      backgroundColor: theme.palette.colors.darkblue,
    },

    statusCell: {
      display: 'flex',
      alignItems: 'center',
    },

    colorEnabled: {
      fill: '#50AF78',
    },
    colorCompleted: {
      fill: '#50AF78',
    },
    colorProvisioned: {
      fill: '#50AF78',
    },
    'colorDe-Provisioned': {
      fill: '#959595',
    },
    colorFailed: {
      fill: '#FF543E',
    },
    colorRequested: {
      fill: '#FF9742',
    },

    /* End of -- ActivityTableCard */

    /* Tool Card Starts here */
    serviceName: {
      color: theme.palette.text.secondary,
      fontStyle: 'normal',
      fontWeight: 'bold',
    },
    toolContentSection: {
      paddingBottom: '0px',
      color: theme.palette.text.tertiary,
    },
    provisioningAlert: {
      display: 'flex',
      flexDirection: 'column',
      gap: `${theme.spacing(1)}px`,
    },
    provisioningAlertIcon: {
      color: theme.palette.alert.color + ' !important',
    },
    toolCard: {
      height: '224px',
    },
    headerAction: {
      marginRight: `0px`,
    },
    cardAvatar: {
      marginTop: `${theme.spacing(0.5)}px`,
    },
    addToolButton: {
      color: theme.palette.text.buttonText,
      backgroundColor: theme.palette.secondary.active,
      textTransform: 'none',
      boxShadow: 'none',
      fontWeight: 'bold',
      '&:hover': {
        color: theme.palette.text.buttonText,
        backgroundColor: theme.palette.secondary.active,
      },
    },
    actionSection: {
      justifyContent: 'space-between',
      padding: `${theme.spacing(2)}px`,
      paddingTop: '0px',
    },
    cardActionButton: {
      color: theme.palette.success.main,
      display: 'flex',
      gap: `${theme.spacing(0.5)}px`,
      textTransform: 'none',
    },

    toolCardTitle: {
      cursor: 'pointer',
    },
    toolCardDescription: {
      wordBreak: 'break-word',
      '-webkit-box-orient': 'vertical',
      '-webkit-line-clamp': '4',
      display: '-webkit-box',
      overflow: 'hidden',
      '& > div': {
        '& > p': {
          '& > a': {
            color: theme.palette.colors.gold,
            '&:visited': {
              color: theme.palette.colors.gold,
            },
          },
        },
      },
    },
    alert: {
      padding: `${theme.spacing(1)}px`,
      marginBottom: `${theme.spacing(2)}px`,
      backgroundColor: theme.palette.alert.background,
      '& > div': {
        backgroundColor: theme.palette.alert.background,
        color: theme.palette.alert.color,
      },
    },

    chip: {
      marginBottom: 0,
    },
    globalTrafficAlert: {
      marginTop: `${theme.spacing(2)}px`,
    },
    /* Tool Card Ends here */

    /* Styles for Project Mapping */

    buttonGroupProjectMapping: {
      gap: `${theme.spacing(2)}px`,
      '& button:not(:last-child)': {
        borderRadius: `${theme.spacing(0.5)}px`,
      },
      '& button:not(:first-child)': {
        borderRadius: `${theme.spacing(0.5)}px`,
      },
      '& button': {
        boxShadow: 'none',
        textTransform: 'none',
        fontWeight: 600,
      },
    },
    buttonInGroup: {
      color: theme.palette.text.tertiary,
      backgroundColor: theme.palette.background.paper,
      '&:hover': {
        backgroundColor: theme.palette.background.paper,
      },
    },
    buttonSelected: {
      color: theme.palette.text.buttonText,
      backgroundColor: theme.palette.secondary.active,
      '&:hover': {
        backgroundColor: theme.palette.secondary.active,
      },
    },
    marginButtonGroup: {
      margin: `${theme.spacing(2)}px 0px`,
    },

    /* Styles for Summary tab page */
    labelAndValue: {
      display: 'flex',
      gap: `${theme.spacing(2)}px`,
      margin: `${theme.spacing(3)}px 0px 0px`,
    },
    cardHeaderTitle: {
      fontSize: `${theme.spacing(2.125)}px`,
    },
    leftSectionContainer: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      maxHeight: `${theme.spacing(25)}px`,
    },
    rightSectionContainer: {
      padding: `0px ${theme.spacing(2)}px`,
    },
    addButtonDetailsSection: {
      textTransform: 'none',
      background: theme.palette.secondary.active,
      fontWeight: 600,
    },
    projectDetail: {
      background: theme.palette.background.default,
      padding: `${theme.spacing(1.25)}px`,
      borderRadius: `${theme.spacing(0.5)}px`,
    },
    childChips: {
      // margin: `0px 0px ${theme.spacing(1.25)}px 0px`,
      background: theme.palette.background.default,
      justifyContent: 'normal',
    },
    chipList: {
      padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    },
    chipSelected: {
      background: theme.palette.secondary.active,
      '&:hover': {
        background: theme.palette.secondary.active,
      },
      '&:focus': {
        background: theme.palette.secondary.active,
      },
    },
    linkProject: {
      display: 'flex',
      gap: `${theme.spacing(1.25)}px`,
      alignItems: 'center',
      justifyContent: 'space-between',
      margin: `${theme.spacing(1.25)}px 0px`,
      overflowWrap: 'anywhere',
    },
    verticalDivider: {
      marginLeft: `-${theme.spacing(2.125)}px`,
      marginRight: `${theme.spacing(1.125)}px`,
    },
    paddingControl: {
      padding: `0px ${theme.spacing(2)}px`,
      overflow: 'auto',
    },
    /* Styles for Summary tab page ends*/

    /* Styles for Operations tab page */
    subTabs: {
      textTransform: 'none',
      border: `1px solid ${theme.palette.primary.main}`,
    },
    subTabSelected: {
      color: theme.palette.text.contrast,
      background: theme.palette.primary.main,
      '&:hover': {
        background: theme.palette.primary.main,
      },
    },
    imageButton: {
      margin: `${theme.spacing(1.25)}px`,
    },
    buttonDimensions: {
      height: `${theme.spacing(15)}px`,
      width: `${theme.spacing(27.5)}px`,
      background: theme.palette.background.default,
      color: theme.palette.text.primary,
      boxShadow: 'none',
      '&:hover': {
        background: theme.palette.bgVariants.variant3,
      },
    },
    imageTileSelected: {
      background: theme.palette.bgVariants.variant3,
      borderLeft: `4px solid #559CEC`,
    },
    iconHeight: {
      height: `${theme.spacing(3.5)}px`,
    },

    /* Styles for Operations tab page ends*/

    /* Styles for project mapping ends */

    /* Performace Test - Service */
    ...performanceTestStyles(theme),
  }),
);
