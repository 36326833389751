import React, { useState, useEffect, useMemo } from 'react';
import { Progress } from '@backstage/core-components';
import { ReviewTable } from './reviewTable';
import {
  ListItem,
  Button as MaterialButton,
  Grid,
  makeStyles,
  createStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import {
  UserListFilter,
  useStarredEntities,
  useEntityOwnership,
  CatalogFilterLayout,
} from '@backstage/plugin-catalog-react';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { useApi } from '@backstage/core-plugin-api';
import { useAsync } from 'react-use';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import CodeIcon from '../../Root/icons/codeIcon';
import ExtensionIcon from '@material-ui/icons/Extension';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import InfoIcon from '@material-ui/icons/Info';
import WidgetsOutlinedIcon from '@material-ui/icons/WidgetsOutlined';
import AIIcon from '../../Root/icons/aiIcon';
import * as constant from '../../utils/constant';
import { HelpComponent } from '../../utils/helpComponent';
import HELP_URL from '../../utils/helpLinkConstant';
import BackButton from '../../utils/backButton';
import SalesforceIcon from '../../Root/icons/salesforceIcon';
import { useGetReviwerListHook } from '../../common/AddReviewerComponent/useGetReviwerListHook';
import { FilterByReviewer } from '../../common/AddReviewerComponent/FilterByReviewer';
import PatternIcon from '../../catalog/icons/PatternIcon';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    themeColor: {
      backgroundColor: theme.palette.background.paper,
      borderLeftColor: theme.palette.primary.main,
      '& li': {
        borderLeftColor: theme.palette.background.paper,
        borderBottom: `1px solid ${theme.palette.border}`,
        '&:hover button': {
          color: theme.palette.primary,
          backgroundColor: `${theme.palette.background.tertiary}`,
        },
        '&.active button': {
          borderLeft: `2px solid ${theme.palette.primary.main}`,
          backgroundColor: `${theme.palette.background.tertiary}`,
        },
      },
    },
    FilteByReviewer: {
      marginTop: '16px',
      '& p': {
        marginBottom: '16px',
      },
    },
  }),
);
const initialState = {
  isCodeFilterClick: false,
  isApiFilterClick: false,
  isAllFilterClick: true,
  isFavoriteFilterClick: false,
  isIacFilterClick: false,
  isSalesforceFilterClick: false,
  isAIFilterClick: false,
  isExtensionFilterClick: false,
  isPatternFilterClick: false,
};

const useCatalogEntity = () => {
  const catalogApi = useApi(catalogApiRef);
  const {
    value: entity,
    error,
    loading,
  } = useAsync(() => catalogApi.getEntities());

  return { entity, error, loading };
};

export const Review = (props: any) => {
  const classes = useStyles();
  const { userName, userInfo } = props;
  const entityData = useCatalogEntity();
  const { entity, error, loading } = entityData;
  const [allAssetsData, setAllAssetsData] = useState<any>([]);
  const [checkClickItem, setCheckClickItem] = useState<any>(initialState);
  const { isStarredEntity } = useStarredEntities();
  const { reviewerList } = useGetReviwerListHook();
  const { isOwnedEntity } = useEntityOwnership();
  const [selectedReviwer, setSelectedReviwer] = useState('All');
  const starredFilter = useMemo(
    () => new UserListFilter('starred', isOwnedEntity, isStarredEntity),
    [isOwnedEntity, isStarredEntity],
  );

  const allCount =
    (allAssetsData &&
      allAssetsData.length &&
      allAssetsData?.filter(
        (item: any) =>
          item.kind?.toLowerCase() === constant.COMPONENT?.toLowerCase() ||
          item.kind?.toLowerCase() === constant.API?.toLowerCase(),
      ).length) ||
    0;

  const codeCount =
    (allAssetsData &&
      allAssetsData.length &&
      allAssetsData?.filter(
        (item: any) =>
          item.kind?.toLowerCase() === constant.COMPONENT?.toLowerCase() &&
          item?.spec?.type?.toLowerCase() !==
            constant.IAC_COMPONENT_TYPE?.toLowerCase() &&
          item?.spec?.type?.toLowerCase() !==
            constant.SALESFORCE_COMPONENT_TYPE?.toLowerCase(),
      ).length) ||
    0;

  const apiCount =
    (allAssetsData &&
      allAssetsData.length &&
      allAssetsData?.filter(
        (item: any) => item.kind?.toLowerCase() === constant.API?.toLowerCase(),
      ).length) ||
    0;

  const iacCount =
    (allAssetsData &&
      allAssetsData.length &&
      allAssetsData.filter(
        (item: any) =>
          item.kind?.toLowerCase() === constant.COMPONENT?.toLowerCase() &&
          item?.spec?.type?.toLowerCase() ===
            constant.IAC_COMPONENT_TYPE?.toLowerCase(),
      ).length) ||
    0;

  const salesforceCount =
    (allAssetsData &&
      allAssetsData.filter(
        (item: any) =>
          item?.metadata?.devxstate?.toLowerCase() !==
            constant.NOT_APPROVED.toLowerCase() &&
          item.kind?.toLowerCase() === constant.COMPONENT &&
          item?.spec?.type?.toLowerCase() ===
            constant.SALESFORCE_COMPONENT_TYPE.toLowerCase(),
      ).length) ||
    0;
  const aiCount =
    allAssetsData &&
    allAssetsData.filter(
      (item: any) =>
        item?.metadata?.devxstate?.toLowerCase() !==
          constant.NOT_APPROVED.toLowerCase() &&
        item.kind?.toLowerCase() === constant.COMPONENT &&
        item?.spec?.type?.toLowerCase() ===
          constant.AI_COMPONENT_TYPE.toLowerCase(),
    ).length;
  const patternCount =
    allAssetsData &&
    allAssetsData.filter(
      (item: any) =>
        item?.metadata?.devxstate?.toLowerCase() !==
          constant.NOT_APPROVED.toLowerCase() &&
        item.kind?.toLowerCase() === constant.COMPONENT &&
        item?.spec?.type?.toLowerCase() === constant.PATTERN_COMPONENT_TYPE.toLowerCase(),
    ).length;
  const extensionCount = 
    allAssetsData &&
    allAssetsData.filter(
      (item: any) =>
        item?.metadata?.devxstate?.toLowerCase() !==
          constant.NOT_APPROVED.toLowerCase() &&
        item.kind?.toLowerCase() === constant.COMPONENT &&
        item?.spec?.type?.toLowerCase() ===
          constant.EXTENSION_COMPONENT_TYPE.toLowerCase(),
    ).length;

  useEffect(() => {
    let entityItems =
      entity &&
      entity?.items
        .filter(
          (item: any) =>
            item.spec?.owner?.toLowerCase() !==
            `user:${userName?.toLowerCase()}`,
        )
        .filter((item: any) => item.spec?.owner?.toLowerCase() !== userName)
        .filter(
          (item: any) =>
            item.metadata?.devxstate?.toLowerCase() ===
            constant.UNDER_REVIEW.toLowerCase(),
        )
        .filter(
          (item: any) =>
            (userInfo?.componentReviewEnabled.toLowerCase() ===
              constant.CHECK_USER_ROLE.toLowerCase() &&
              userInfo?.isComponentReviewer?.toLowerCase() ===
                constant.CHECK_USER_ROLE.toLowerCase() &&
              item.kind?.toLowerCase() === constant.COMPONENT?.toLowerCase() &&
              constant.COMPONENT_TYPE.includes(
                item?.spec?.type?.toLowerCase(),
              )) ||
            (userInfo?.apiReviewEnabled?.toLowerCase() ===
              constant.CHECK_USER_ROLE.toLowerCase() &&
              userInfo?.isApiReviewer?.toLowerCase() ===
                constant.CHECK_USER_ROLE.toLowerCase() &&
              item.kind?.toLowerCase() === constant.API?.toLowerCase()),
        );

    if (selectedReviwer && selectedReviwer != 'All') {
      entityItems =
        entityItems &&
        entityItems?.filter(
          (item: any) =>
            item?.metadata?.reviewer &&
            item?.metadata?.reviewer[0] === selectedReviwer,
        );
    }

    setAllAssetsData(entityItems);
  }, [entity, error, loading, userInfo, userName, selectedReviwer]);

  useEffect(() => {}, [allAssetsData]);

  const starredFilterItem =
    (allAssetsData &&
      allAssetsData.length &&
      allAssetsData.filter((item: any) => starredFilter.filterEntity(item))) ||
    [];

  const handleOnClickForCode = () => {
    setCheckClickItem({
      ...initialState,
      isCodeFilterClick: true,
      isAllFilterClick: false,
    });
  };

  const handleOnClickForApi = () => {
    setCheckClickItem({
      ...initialState,
      isApiFilterClick: true,
      isAllFilterClick: false,
    });
  };

  const handleOnClickForFavorite = () => {
    setCheckClickItem({
      ...initialState,
      isFavoriteFilterClick: true,
      isAllFilterClick: false,
    });
  };

  const handleOnClickForAll = () => {
    setCheckClickItem(initialState);
  };

  const handleOnClickForIac = () => {
    setCheckClickItem({
      ...initialState,
      isIacFilterClick: true,
      isAllFilterClick: false,
    });
  };
  const handleOnClickForSalesforce = () => {
    setCheckClickItem({
      ...initialState,
      isSalesforceFilterClick: true,
      isAllFilterClick: false,
    });
  };
  const handleOnClickForAI = () => {
    setCheckClickItem({
      ...initialState,
      isAIFilterClick: true,
      isAllFilterClick: false,
    });
  };
  const handleOnClickForPattern = () => {
    setCheckClickItem({
      ...initialState,
      isPatternFilterClick: true,
      isAllFilterClick: false,
    });
  };
  const handleOnClickForExtension = () => {
    setCheckClickItem({
      ...initialState,
      isExtensionFilterClick: true,
      isAllFilterClick: false,
    });
  }

  if (loading) {
    return (
      <div>
        <Progress />
      </div>
    );
  }

  return (
    <div className="codePage noPadding">
      <Grid item md={12} xs={12}>
        <BackButton />
        <HelpComponent helpUrl={HELP_URL.Review} />
      </Grid>
      <CatalogFilterLayout>
        <CatalogFilterLayout.Filters>
          <div className={`customFilters ${classes.themeColor}`}>
            <ListItem
              className={checkClickItem.isAllFilterClick ? 'active' : ''}
            >
              <MaterialButton
                variant="outlined"
                name="All"
                onClick={() => handleOnClickForAll()}
                startIcon={<DoneAllIcon />}
              >
                All
                <span className="CountSpan">{allCount}</span>
              </MaterialButton>
            </ListItem>
            <ListItem
              className={checkClickItem.isFavoriteFilterClick ? 'active' : ''}
            >
              <MaterialButton
                variant="outlined"
                name="Favorite"
                onClick={() => handleOnClickForFavorite()}
                startIcon={<BookmarkIcon />}
              >
                Bookmark
                <span className="CountSpan">
                  {starredFilterItem && starredFilterItem.length}
                </span>
              </MaterialButton>
            </ListItem>

            <ListItem
              className={checkClickItem.isCodeFilterClick ? 'active' : ''}
            >
              <MaterialButton
                variant="outlined"
                name="code"
                onClick={() => handleOnClickForCode()}
                startIcon={<CodeIcon />}
              >
                Code/Library<span className="CountSpan">{codeCount}</span>
              </MaterialButton>
            </ListItem>
            <ListItem
              className={checkClickItem.isApiFilterClick ? 'active' : ''}
            >
              <MaterialButton
                variant="outlined"
                name="api"
                onClick={() => handleOnClickForApi()}
                startIcon={<ExtensionIcon />}
              >
                API
                <span className="CountSpan">{apiCount}</span>
              </MaterialButton>
            </ListItem>
            <ListItem
              className={checkClickItem.isIacFilterClick ? 'active' : ''}
            >
              <MaterialButton
                variant="outlined"
                name="iac"
                onClick={() => handleOnClickForIac()}
                startIcon={<InfoIcon />}
              >
                IaC
                <span className="CountSpan">{iacCount}</span>
              </MaterialButton>
            </ListItem>
            {salesforceCount > 0 && (
              <ListItem
                className={
                  checkClickItem.isSalesforceFilterClick ? 'active' : ''
                }
              >
                <MaterialButton
                  variant="outlined"
                  name="salesforce"
                  onClick={() => handleOnClickForSalesforce()}
                  startIcon={<SalesforceIcon />}
                >
                  Salesforce
                  <span className="CountSpan">{salesforceCount}</span>
                </MaterialButton>
              </ListItem>
            )}
            {aiCount > 0 && (
              <ListItem
                className={checkClickItem.isAIFilterClick ? 'active' : ''}
              >
                <MaterialButton
                  variant="outlined"
                  name="salesforce"
                  onClick={() => handleOnClickForAI()}
                  startIcon={<AIIcon />}
                >
                  AI Accelerators
                  <span className="CountSpan">{aiCount}</span>
                </MaterialButton>
              </ListItem>
            )}
            {patternCount > 0 && (
              <ListItem
                className={checkClickItem.isPatternFilterClick ? 'active' : ''}
              >
                <MaterialButton
                  variant="outlined"
                  name="salesforce"
                  onClick={() => handleOnClickForPattern()}
                  startIcon={<PatternIcon />}
                >
                  Arch. Patterns
                  <span className="CountSpan">{patternCount}</span>
                </MaterialButton>
              </ListItem>
            )}
            {
              extensionCount > 0 && (
                <ListItem
                  className={checkClickItem.isExtensionFilterClick ? 'active' : ''}
                >
                  <MaterialButton
                    variant="outlined"
                    name="salesforce"
                    onClick={() => handleOnClickForExtension()}
                    startIcon={<WidgetsOutlinedIcon fontSize="large" />}
                  >
                    Extensions
                    <span className="CountSpan">{extensionCount}</span>
                  </MaterialButton>
                </ListItem>
              )
            }
          </div>
          {constant.IS_ADD_Reviewer_IN_ASSETS && (
            <div className={classes.FilteByReviewer}>
              <Typography>Filter By Reviewer</Typography>
              <FilterByReviewer
                reviewerList={reviewerList || []}
                setSelectedReviwer={setSelectedReviwer}
                selectedReviwer={selectedReviwer}
              />
            </div>
          )}
        </CatalogFilterLayout.Filters>
        <CatalogFilterLayout.Content>
          <ReviewTable
            entities={allAssetsData}
            error={error}
            loading={loading}
            checkFilterItem={checkClickItem}
            starredFilterItem={starredFilterItem || []}
            reviewerList={reviewerList || []}
          />
        </CatalogFilterLayout.Content>
      </CatalogFilterLayout>
    </div>
  );
};
