import React, { useState, useMemo, useEffect } from 'react';
import {
  Grid,
  IconButton,
  InputBase,
  LinearProgress,
  Paper,
  TablePagination,
} from '@material-ui/core';
import { PocCard } from '../common/PocCard';
import {
  CatalogFilterLayout,
  EntityKindPicker,
  EntityTypePicker,
  UserListFilter,
  useEntityOwnership,
  useStarredEntities,
} from '@backstage/plugin-catalog-react';
import { useEntityList } from '@backstage/plugin-catalog-react';
import ClearButton from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import * as constant from '../../utils/constant';
import { NoPocFound } from '../NoPocFound/NoPocFound';
import { useStyles } from '../common/styles';
import { EntityTagPicker } from '../../utils/EntityTagPicker';
import { UserListPicker } from '../../filters/UserListPicker';

export const PocListContainer = () => {
  const classes = useStyles();
  const { loading, entities } = useEntityList();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const filterString = (str: any) => str.toLowerCase();
  const [_matchArray, setMatchArray] = useState<any[]>([]);
  const [_paginatePageNumber, setPaginatePageNumber] = useState(0);
  const _paginateRowsPerPage = 20;
  const [_paginateCount, setPaginateCount] = useState(0);
  const [filteredEntities, setFilteredEntities] = useState<any[]>([]);
  const { isOwnedEntity } = useEntityOwnership();
  const params = new URLSearchParams(window.location.search);
  const { isStarredEntity, toggleStarredEntity } = useStarredEntities();
  const starredFilter = useMemo(
    () => new UserListFilter('starred', isOwnedEntity, isStarredEntity),
    [isOwnedEntity, isStarredEntity],
  );

  const starredFilterItem =
    filteredEntities &&
    filteredEntities.length &&
    filteredEntities.filter((item: any) => starredFilter.filterEntity(item));

  const entityCreationDate = (a: any) => {
    if (!a.metadata?.creationTimestamp) return 0;
    return new Date(a?.metadata?.creationTimestamp).getTime();
  };

  useEffect(() => {
    let filterData = entities
      ? entities.filter(
          (item: any) =>
            item.spec?.type?.toLowerCase() === 'poc' &&
            (
              (item.metadata?.annotations ?? {})[
                'backstage.io/source-location'
              ] || ''
            )
              .toLowerCase()
              .includes('poc_2025_') &&
            item.kind?.toLowerCase() === constant.COMPONENT.toLowerCase(),
        )
      : [];

    if (searchTerm != '') {
      filterData = filterData.filter(entity => {
        let match = false;
        const filteredSearchTerm = filterString(searchTerm);
        if (
          (entity?.spec?.owner &&
            filterString(entity?.spec?.owner).includes(filteredSearchTerm)) ||
          (entity?.metadata?.name &&
            filterString(entity?.metadata?.name).includes(
              filteredSearchTerm,
            )) ||
          (entity?.metadata?.description &&
            filterString(entity?.metadata?.description).includes(
              filteredSearchTerm,
            )) ||
          (entity?.metadata?.tags &&
            entity?.metadata?.tags.find((tag: string) =>
              tag.toLowerCase().includes(filteredSearchTerm),
            ))
        ) {
          match = true;
        }
        return match;
      });
    }
    filterData = filterData.sort(
      (a: any, b: any) => entityCreationDate(b) - entityCreationDate(a),
    );
    setPaginatePageNumber(0);
    setMatchArray(filterData || []);
    setPaginateCount(filterData.length);
  }, [entities, searchTerm]);

  useEffect(() => {
    const begin = _paginatePageNumber * _paginateRowsPerPage;
    const end = begin + _paginateRowsPerPage;
    setFilteredEntities([..._matchArray.slice(begin, end)]);
  }, [_paginatePageNumber, _matchArray]);

  const onPageChange = (
    event: React.SyntheticEvent | null,
    pageNumber: number,
  ): void => {
    if (event) {
      event.preventDefault();
    }
    setPaginatePageNumber(pageNumber);
  };

  const NoPocs = () => {
    if (
      !loading &&
      params.get('filters[user]') === 'owned' &&
      filteredEntities &&
      filteredEntities?.length === 0 &&
      !searchTerm
    )
      return <NoPocFound />;

    if (
      !loading &&
      filteredEntities &&
      filteredEntities?.length === 0 &&
      searchTerm
    )
      return (
        <p>
          No POCs matching the search criteria found. Please clear the filter
          and try again.
        </p>
      );

    if (
      !loading &&
      params.get('filters[user]') === 'starred' &&
      filteredEntities &&
      filteredEntities?.length === 0 &&
      !searchTerm
    )
      return <p>There are no bookmarked POCs !</p>;

    if (
      loading &&
      filteredEntities &&
      filteredEntities?.length === 0 &&
      !searchTerm
    )
      return <p>Loading...</p>;

    if (!loading && filteredEntities?.length === 0)
      return <p>No entities found.</p>;

    return null;
  };

  return (
    <>
      {loading && <LinearProgress />}
      <Grid container className={classes.listContainerMargin}>
        <CatalogFilterLayout>
          <CatalogFilterLayout.Filters>
            {/* <EntityKindPicker initialFilter="component" hidden /> */}
            <EntityTypePicker initialFilter="poc" hidden />
            <UserListPicker initialFilter="all" parentName="poc" />
            <EntityTagPicker />
          </CatalogFilterLayout.Filters>
          <CatalogFilterLayout.Content>
            <div className={classes.ContentHeaderStyle}>
              <label htmlFor="search-input" hidden>
                Search Here
              </label>
              <Paper component="form">
                <IconButton disabled type="submit" aria-label="search">
                  <SearchIcon />
                </IconButton>
                <InputBase
                  className={classes.input}
                  placeholder="Search"
                  value={searchTerm}
                  onChange={event => {
                    setSearchTerm(event?.target?.value);
                    return;
                  }}
                  inputProps={{ 'aria-label': 'search' }}
                />
                <IconButton
                  aria-label="search"
                  onClick={() => {
                    setSearchTerm('');
                    return;
                  }}
                >
                  <ClearButton />
                </IconButton>
              </Paper>
            </div>
            <Grid container direction="row" spacing={2}>
              {filteredEntities && filteredEntities.length ? (
                <>
                  {filteredEntities?.map((item: any) => (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={3}
                      lg={4}
                      xl={2}
                      className={classes.pocCardContainer}
                      key={item?.metadata?.uid}
                    >
                      <PocCard
                        item={item}
                        checkFilterItem={{
                          isFavoriteFilterClick: false,
                        }}
                        starredFilterItem={starredFilterItem || []}
                      />
                    </Grid>
                  ))}
                </>
              ) : (
                <div className={classes.pocContainerCard}>
                  <NoPocs />
                </div>
              )}
            </Grid>
            {filteredEntities && filteredEntities.length ? (
              <div className={classes.PaginationBar}>
                <Paper>
                  <TablePagination
                    SelectProps={{
                      title: 'perPageSelect',
                      native: true,
                    }}
                    component="div"
                    page={_paginatePageNumber}
                    rowsPerPage={_paginateRowsPerPage}
                    count={_paginateCount}
                    onPageChange={onPageChange}
                    rowsPerPageOptions={[]}
                  />
                </Paper>
              </div>
            ) : null}
          </CatalogFilterLayout.Content>
        </CatalogFilterLayout>
      </Grid>
    </>
  );
};
