import React, { useRef } from 'react';
import { Grid, Typography, Chip, TextareaAutosize } from '@material-ui/core';
import { InfoCard } from '@backstage/core-components';
import { Theme, createStyles, makeStyles } from '@material-ui/core';
import {
  iacType,
  deprecatedLifecycle,
  experimentalLifecycle,
  productionLifecycle,
  packageType,
  utilityType,
  frameworkType,
  aiType,
  SALESFORCE_COMPONENT_TYPE,
  salesforceType,
  extensionType,
  EXTENSION_COMPONENT_TYPE,
} from '../utils/constant';
import { useApi } from '@backstage/core-plugin-api';
import { userProfileApiRef } from '../../apis/userProfileApi';
import { getUserName } from '../home/allAssets/util';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    LifeCycleTypography: {
      marginBottom: '10px',
    },
    heading: {
      color: theme.palette.text.tertiary,
      fontSize: '12px',
      fontWeight: 600,
      marginLeft: '10px',
    },
    subcontent: {
      color: theme.palette.text.secondary,
      fontSize: '11px',
      fontWeight: 600,
      marginLeft: '10px',
    },
    changemargin: {
      marginLeft: '10px',
    },
    infocardspecs: {
      minHeight: 400,
      // background: '#fff',
    },
    label: {
      fontSize: '11px',
    },
    label2: {
      textTransform: 'uppercase',
      fontSize: '12px',
      lineHeight: 1.5,
      fontWeight: 600,
      letterSpacing: 0.5,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      fontFamily: 'Calibre-R-Regular',
      color: theme.palette.text.tertiary,
    },
    descriptionText: {
      padding: '5px 12px',
      margin: '8px 0',
      boxSizing: 'border-box',
      border: '1px solid #f1f1f1',
      color: theme.palette.text.secondary,
      fontSize: '14px',
      fontFamily: 'Calibre-R-Regular',
      width: '100% !important',
      backgroundColor: theme.palette.background.paper,
    },
  }),
);

export const SpecsCard = (props: {
  className: any;
  defaultEntityOwner?: any;
  lifCycleSelected?: any;
  setLifCycleSelected?: any;
  setTypeSelected?: any;
  typeSelected?: any;
  setIsButtonDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  type?: string;
  downloadLink?: string;
  setDownloadLink?: any;
}) => {
  const classes = useStyles();
  const {
    defaultEntityOwner,
    lifCycleSelected,
    setLifCycleSelected,
    setTypeSelected,
    typeSelected,
    setIsButtonDisabled,
    type,
    downloadLink,
    setDownloadLink
  } = props;

  const userProfileApi = useApi(userProfileApiRef);
  const usernameRef = useRef(null);

  const handleOnclickPackge = () => {
    setTypeSelected('package');
    setIsButtonDisabled(false);
  };
  const handleOnclickUTILITY = () => {
    setTypeSelected('utility');
    setIsButtonDisabled(false);
  };
  const handleOnclickFRAMEWORK = () => {
    setTypeSelected('framework');
    setIsButtonDisabled(false);
  };
  const handleOnClickAI = () => {
    setTypeSelected('ai');
    setIsButtonDisabled(false);
  }
  const handleOnClickExtension = () => {
    setTypeSelected('extension');
    setIsButtonDisabled(false);
  }
  const handleOnClickIac = () => {
    setTypeSelected('iac');
    setIsButtonDisabled(false);
  };
  const handleOnClickPattern = () => {
    setTypeSelected('pattern');
    setIsButtonDisabled(false);
  }
  const handleOnclickDEPRECATED = () => {
    setLifCycleSelected('deprecated');
    setIsButtonDisabled(false);
  };
  const handleOnclickEXPERIMENTAL = () => {
    setLifCycleSelected('experimental');
    setIsButtonDisabled(false);
  };
  const handleOnclickPRODUCTION = () => {
    setLifCycleSelected('production');
    setIsButtonDisabled(false);
  };
  const handleOnClickSalesforce = () => {
    setTypeSelected('salesforce');
    setIsButtonDisabled(false);
  };
  const handleDownLoadLinkUpdate = (e: any) => {
    e.preventDefault();
    setDownloadLink(e.target.value);
    setIsButtonDisabled(false);
  }

  const onMouseEnter = () => {
    const owner = getUserName(defaultEntityOwner);
    const email = `${owner}@cbre.com`;

    userProfileApi.setUserProfileState({
      email,
      anchorRef: usernameRef?.current,
    });
  };

  const RenderType = () => {
    if (typeSelected === iacType.toLocaleLowerCase()) {
      return (
        <Chip
          size="small"
          label={iacType}
          classes={{
            label: classes.label,
          }}
          className={classes.label}
          variant={typeSelected === 'iac' ? 'outlined' : 'default'}
          color={typeSelected === 'iac' ? 'primary' : 'default'}
          clickable
          onClick={handleOnClickIac}
        />
      );
    } else if (typeSelected === SALESFORCE_COMPONENT_TYPE.toLocaleLowerCase()) {
      return (
        <Chip
          size="small"
          label={salesforceType}
          classes={{
            label: classes.label,
          }}
          className={classes.label}
          variant={typeSelected === 'salesforce' ? 'outlined' : 'default'}
          color={typeSelected === 'salesforce' ? 'primary' : 'default'}
          clickable
          onClick={handleOnClickSalesforce}
        />
      );
    }
    return (
      <>
        <Chip
          size="small"
          label={packageType}
          classes={{
            label: classes.label,
          }}
          variant={typeSelected === 'package' ? 'outlined' : 'default'}
          className={classes.label}
          color={typeSelected === 'package' ? 'primary' : 'default'}
          clickable
          onClick={handleOnclickPackge}
        />
        <Chip
          size="small"
          label={utilityType}
          classes={{
            label: classes.label,
          }}
          variant={typeSelected === 'utility' ? 'outlined' : 'default'}
          className={classes.label}
          color={typeSelected === 'utility' ? 'primary' : 'default'}
          clickable
          onClick={handleOnclickUTILITY}
        />
        <Chip
          size="small"
          label={frameworkType}
          classes={{
            label: classes.label,
          }}
          variant={typeSelected === 'framework' ? 'outlined' : 'default'}
          className={classes.label}
          color={typeSelected === 'framework' ? 'primary' : 'default'}
          clickable
          onClick={handleOnclickFRAMEWORK}
        />
        <Chip
          size="small"
          label={aiType}
          classes={{
            label: classes.label,
          }}
          variant={typeSelected === 'ai' ? 'outlined' : 'default'}
          className={classes.label}
          color={typeSelected === 'ai' ? 'primary' : 'default'}
          clickable
          onClick={handleOnClickAI}
        />
        <Chip
          size="small"
          label={extensionType}
          classes={{
            label: classes.label,
          }}
          variant={typeSelected === 'extension' ? 'outlined' : 'default'}
          className={classes.label}
          color={typeSelected === 'extension' ? 'primary' : 'default'}
          clickable
          onClick={handleOnClickExtension}
        />
        <Chip
          size="small"
          label="Arch. Pattern"
          classes={{
            label: classes.label,
          }}
          variant={typeSelected === 'pattern' ? 'outlined' : 'default'}
          className={classes.label}
          color={typeSelected === 'pattern' ? 'primary' : 'default'}
          clickable
          onClick={handleOnClickPattern}
        />
      </>
    );
  };

  return (
    <InfoCard className={classes.infocardspecs} title="Specs">
      <Grid container direction="row">
        <Grid container direction="row">
          <Grid item lg={12} md={12} sm={12}>
            <Typography className={classes.heading}>OWNER</Typography>
            <Typography className={classes.subcontent}>
              <span onMouseEnter={onMouseEnter} ref={usernameRef}>
                {defaultEntityOwner?.replace('user:', '')}
              </span>
            </Typography>
          </Grid>
        </Grid>
        <Grid item lg={6} md={6} sm={12} />
        <Grid container direction="row">
          <Grid item lg={6} md={6} sm={12}>
            <Typography className={classes.heading}>LIFECYCLE</Typography>

            <Typography className={classes.changemargin} component="div">
              <Chip
                size="small"
                label={deprecatedLifecycle}
                variant={
                  lifCycleSelected === 'deprecated' ? 'outlined' : 'default'
                }
                classes={{
                  label: classes.label,
                }}
                className={classes.label}
                color={
                  lifCycleSelected === 'deprecated' ? 'primary' : 'default'
                }
                clickable
                onClick={handleOnclickDEPRECATED}
              />
              <Chip
                size="small"
                label={experimentalLifecycle}
                variant={
                  lifCycleSelected === 'experimental' ? 'outlined' : 'default'
                }
                classes={{
                  label: classes.label,
                }}
                className={classes.label}
                color={
                  lifCycleSelected === 'experimental' ? 'primary' : 'default'
                }
                clickable
                onClick={handleOnclickEXPERIMENTAL}
              />
            </Typography>
            <Typography className={classes.changemargin} component="div">
              <Chip
                data-testid="background"
                size="small"
                label={productionLifecycle}
                variant={
                  lifCycleSelected === 'production' ? 'outlined' : 'default'
                }
                classes={{
                  label: classes.label,
                }}
                className={classes.label}
                color={
                  lifCycleSelected === 'production' ? 'primary' : 'default'
                }
                clickable
                onClick={handleOnclickPRODUCTION}
              />
            </Typography>
          </Grid>

          <Grid item lg={6} md={6} sm={12}>
            <Typography className={classes.heading}>TYPE</Typography>
            <Typography className={classes.changemargin} component="div">
              <RenderType />
            </Typography>
          </Grid>
        </Grid>

        {
          typeSelected && typeSelected.toLowerCase() === EXTENSION_COMPONENT_TYPE && <Grid item lg={6} md={6} sm={12}>
            <Typography variant="subtitle2" className={classes.label2}>
              DOWNLOAD LINK
            </Typography>
            <TextareaAutosize
              id="DOWNLOAD LINK"
              minRows={3}
              maxRows={5}
              onChange={handleDownLoadLinkUpdate}
              value={downloadLink}
              className={classes.descriptionText}
            />
            </Grid>
        }

        <Grid container spacing={10} direction="column">
          <Grid item xs={6} />
          <Grid item xs={6} />
        </Grid>
      </Grid>
    </InfoCard>
  );
};
