import React from 'react';
import { useStyles } from '../../styles';
import { IconButton } from '@material-ui/core';
import LaunchIcon from '@material-ui/icons/Launch';

const List = ({ projectName, toolName, subHeader, url }: any) => {
  const classes = useStyles();

  return (
    <div className={`${classes.marginTop2} ${classes.marginBottomSmall}`}>
      <div className={`${classes.marginTop2} ${classes.projectDetail}`}>
        <div className={classes.flexSpacebetween}>
          <div>
            <span className={classes.fontBold}>{projectName}</span>
            {(toolName || subHeader) && (
              <>
                <span className={classes.fontBold}> | </span>
                <span>
                  {toolName} {subHeader}
                </span>
              </>
            )}
          </div>
          {url && (
            <IconButton
              href={url}
              target="_blank"
              classes={{ root: classes.padding0 }}
            >
              <LaunchIcon fontSize="small" />
            </IconButton>
          )}
        </div>
      </div>
    </div>
  );
};

export default List;
