const CONSTANTS = {
  LABELS: {
    CHILD: 'Child',
    ALM: 'ALM',
  },
  DATAKEYS: {
    REPOSITORY_CONFIG: 'RepositoryConfig',
    AREA_PATH: 'AreaPath',
  },
};
export default CONSTANTS;
