import { Button, ButtonGroup, Grid, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useStyles } from '../../styles';
import { useParams } from 'react-router';
import { useGetServicesMapping } from '../../hooks/useGetServicesMapping';
import { Progress } from '@backstage/core-components';
import ToolsDetails from './ToolsDetails';
import CONSTANTS from './constants';

const { REPOSITORY_CONFIG, AREA_PATH } = CONSTANTS.DATAKEYS;
const { CHILD, ALM } = CONSTANTS.LABELS;

const ProjectMapping = () => {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState('');
  const { projectId } = useParams();
  const [servicesMapping, loading] = useGetServicesMapping(projectId!, true);
  const [labels, setLabels] = useState<string[]>([]);

  useEffect(() => {
    if (
      !loading &&
      servicesMapping &&
      servicesMapping.data &&
      servicesMapping.data[0]
    ) {
      const extractedLabels = servicesMapping.data[0]?.data
        .filter((data: any) => data.label) // Filter objects that have the label key
        .map((data: any) => data.label); // Map to extract the label values
      const uniqueLabels = Array.from<string>(new Set(extractedLabels));
      setLabels(uniqueLabels || []);
    }
  }, [loading, servicesMapping]);

  const [buttons, setButtons] = useState<{ name: string; selected: boolean }[]>(
    [],
  );

  useEffect(() => {
    if (labels.length > 0) {
      // Filter out 'Child' label first
      const filteredLabels = labels.filter(
        label => label.toLowerCase() !== CHILD.toLowerCase(),
      );

      // Set buttons with filtered labels
      setButtons(
        filteredLabels.map((label: string, index: number) => ({
          name: label,
          selected: index === 0,
        })),
      );

      // Set selectedTab with first filtered label
      if (filteredLabels.length > 0) {
        setSelectedTab(filteredLabels[0]);
      }
    }
  }, [labels]);

  const handleTabChange = (tabName: string) => {
    setSelectedTab(tabName);
    setButtons(
      buttons.map(button => {
        return { ...button, selected: button.name === tabName };
      }),
    );
  };

  const processedServices = {
    ...servicesMapping,
    data:
      !loading && servicesMapping?.data?.[0]
        ? JSON.parse(JSON.stringify(servicesMapping.data)).map(item => {
            const mergedData = item?.data?.reduce(
              (acc: any[], nestedItem: any) => {
                // Handle ALM label items (always keep them)
                if (nestedItem.label.toLowerCase() === ALM.toLowerCase()) {
                  acc.push(nestedItem);
                  return acc;
                }

                if (nestedItem.label.toLowerCase() === CHILD.toLowerCase()) {
                  const matchingItem = item.data.find(
                    (otherItem: any) =>
                      otherItem.service === nestedItem.service &&
                      otherItem !== nestedItem &&
                      otherItem.label.toLowerCase() === ALM.toLowerCase(),
                  );

                  if (matchingItem) {
                    if (
                      nestedItem.datakey.toLowerCase() ===
                      REPOSITORY_CONFIG.toLowerCase()
                    ) {
                      // Process repository data
                      const projectItem = matchingItem.data[0];
                      if (projectItem) {
                        projectItem.repos = projectItem.repos || [];
                        projectItem.repos.push(...nestedItem.data);
                      }
                    } else if (
                      nestedItem.datakey.toLowerCase() ===
                      AREA_PATH.toLowerCase()
                    ) {
                      // Process area path data
                      const projectItem = matchingItem.data[0];
                      if (projectItem) {
                        projectItem.areapaths = projectItem.areapaths || [];
                        projectItem.areapaths.push(...nestedItem.data);
                      }
                    }
                  } else {
                    // If no matching ALM item found, keep the original item
                    acc.push(nestedItem);
                  }
                } else {
                  // If no matching ALM item found, keep the original item
                  acc.push(nestedItem);
                }
                return acc;
              },
              [],
            );
            return {
              ...item,
              data: mergedData || [],
            };
          })
        : [],
  };

  const filteredParentObject = {
    ...processedServices,
    data: processedServices.data.map(item => {
      return {
        ...item,
        data: item.data.filter((nestedItem: any) => {
          return nestedItem.label === selectedTab;
        }),
      };
    }),
  };

  return (
    <>
      {!loading &&
      servicesMapping &&
      servicesMapping.data &&
      servicesMapping.data[0] ? (
        <>
          <Grid container className={classes.marginButtonGroup}>
            <Grid item>
              <ButtonGroup className={classes.buttonGroupProjectMapping}>
                {buttons.map(button => (
                  <Button
                    key={button.name}
                    variant="contained"
                    color="primary"
                    className={
                      button.selected
                        ? classes.buttonSelected
                        : classes.buttonInGroup
                    }
                    onClick={() => {
                      handleTabChange(button.name);
                    }}
                  >
                    {button.name}
                  </Button>
                ))}
              </ButtonGroup>
            </Grid>
          </Grid>
          <ToolsDetails
            selectedTab={selectedTab}
            imageButtonsALM={filteredParentObject && filteredParentObject}
          />
        </>
      ) : loading ? (
        <Progress />
      ) : (
        <Typography>No details to show.</Typography>
      )}
    </>
  );
};

export default ProjectMapping;
