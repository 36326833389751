import { Button } from '@material-ui/core';
import React from 'react';
import { useStyles } from '../../styles';
import { ImageTileProps } from '../../interfaces/projectMappingInterfaces';

const ImageTile = ({
  imageUrl,
  header,
  subHeader,
  onClick,
  isSelected,
}: ImageTileProps) => {
  const classes = useStyles();

  return (
    <Button
      classes={{
        label: `${classes.flexColumnStart} ${classes.gap1}`,
        root: classes.buttonDimensions,
      }}
      className={`${classes.imageButton} ${
        isSelected ? classes.imageTileSelected : ''
      }`}
      variant="contained"
      onClick={onClick}
    >
      {/* <div>
        <img src={imageUrl} className={classes.iconHeight} />
      </div> */}
      <div dangerouslySetInnerHTML={{ __html: imageUrl }} />
      <div className={`${classes.flexColumnStart} ${classes.userCase}`}>
        <span className={classes.fontBold}>{header}</span>
        <span>{subHeader}</span>
      </div>
    </Button>
  );
};

export default ImageTile;
