import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import ProjectMappingCard from '../cards/ProjectMappingCard';
import {
  Button,
  ToolsDetailsProps,
} from '../../interfaces/projectMappingInterfaces';
import AddButton from './AddButton';
import ChipsFilter from './ChipsFilter';
import ListCard from './ListCard';

const ToolsDetails = ({
  subTabs,
  selectedTab,
  imageButtonsALM,
}: ToolsDetailsProps) => {
  const [selectedButton, setSelectedButton] = useState<Button | null>(null);

  const handleSelectedButtonChange = (button: Button) => {
    setSelectedButton(button);
  };

  const filterComponent = ChipsFilter({ data: selectedButton?.data });

  const buttonListCardTitle = 'Tools';
  const listTitle = 'Details';

  return (
    <Grid container>
      <Grid item md={8} lg={8}>
        <ProjectMappingCard
          cardTitle={buttonListCardTitle}
          subTabs={subTabs}
          imageButtonsALM={imageButtonsALM}
          selectedTab={selectedTab}
          onSelectedButtonChange={handleSelectedButtonChange}
        />
      </Grid>
      <Grid item md={4} lg={4}>
        <ListCard
          cardTitle={listTitle}
          toolName={selectedButton?.service}
          subHeader={selectedButton?.datakey}
          data={selectedButton?.data}
          ActionComponent={<AddButton toolName="ado" hasChildren={false} />}
          FilterComponent={filterComponent}
        />
      </Grid>
    </Grid>
  );
};

export default ToolsDetails;
