import {
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  Divider,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useStyles } from '../../styles';
import { ButtonListCardProps } from '../../interfaces/projectMappingInterfaces';
import ImageTile from '../common/ImageTile';

const ProjectMappingCard = ({
  cardTitle,
  subTabs: initialSubTabs,
  imageButtonsALM,
  selectedTab,
  onSelectedButtonChange,
}: ButtonListCardProps) => {
  const classes = useStyles();

  const [subTabs, setSubTabs] = useState(initialSubTabs);
  const [selectedButtonId, setSelectedButtonId] = useState<number | null>(null);

  const selectedSubButton = subTabs?.find(button => button.selected)?.name;

  const filteredImageButtons =
    (subTabs?.length ?? 0 > 0) && subTabs
      ? imageButtonsALM?.filter(button => button.toolType === selectedSubButton)
      : imageButtonsALM !== undefined && imageButtonsALM?.data[0];

  const handleChangeOfSubTab = (tabName: string) => {
    setSubTabs(
      subTabs?.map(button => ({
        ...button,
        selected: button.name === tabName,
      })),
    );
  };

  const handleImageTileClick = (id: number) => {
    if (setSelectedButtonId) {
      setSelectedButtonId(id);
    }
    const selectedButton = filteredImageButtons?.data[id];
    onSelectedButtonChange(selectedButton);
  };

  useEffect(() => {
    // Reset the selected state of image buttons when switching sub-tabs
    if (setSelectedButtonId) {
      setSelectedButtonId(0);
      onSelectedButtonChange(filteredImageButtons?.data[0] || null);
    }
  }, [selectedTab, selectedSubButton, setSelectedButtonId]);

  return (
    <Card classes={{ root: classes.height400 }}>
      <CardHeader
        title={cardTitle && cardTitle}
        classes={{
          root: classes.paddingBottom2,
          title: classes.cardHeaderTitle,
        }}
        action={
          subTabs && (
            <ButtonGroup>
              {subTabs?.map((button, index) => (
                <Button
                  key={index}
                  onClick={() => {
                    handleChangeOfSubTab(button.name);
                  }}
                  className={`${classes.subTabs} ${
                    button.selected && classes.subTabSelected
                  }`}
                >
                  {button.name}
                </Button>
              ))}
            </ButtonGroup>
          )
        }
      />
      <Divider />
      <CardContent>
        {filteredImageButtons?.data
          ?.filter(data => data.datakey !== 'ProjectMeta')
          ?.filter(data => data.label)
          .map((data, index) => {
            const serviceIconKey = data?.service?.toLowerCase();
            const imageUrl =
              Object.entries(imageButtonsALM?.toolImages || {}).find(
                ([key, value]) => key.toLowerCase() === serviceIconKey,
              )?.[1] || null;
            return (
              <ImageTile
                key={index}
                imageUrl={imageUrl}
                header={
                  data.hasChild
                    ? data?.data[0]?.projectname
                      ? data.data[0].projectname
                      : data.data[0]
                    : data.service
                }
                subHeader={data.hasChild ? 'Boards and Repos' : data.datakey}
                onClick={() => handleImageTileClick(index)}
                isSelected={selectedButtonId === index}
              />
            );
          })}
        {!filteredImageButtons?.data && 'No tools to show.'}
      </CardContent>
    </Card>
  );
};

export default ProjectMappingCard;
