import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const PatternIcon = () => {

return <SvgIcon xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M11.1 11.9L8.40002 19.35C8.38336 19.4 8.34169 19.4583 8.27502 19.525L7.47502 20.3C7.32502 20.45 7.15419 20.4875 6.96252 20.4125C6.77086 20.3375 6.65836 20.2 6.62502 20L6.52502 18.925L6.55002 18.7L9.35002 10.95C9.60002 11.1833 9.87086 11.3792 10.1625 11.5375C10.4542 11.6958 10.7667 11.8167 11.1 11.9ZM12 11C11.1667 11 10.4584 10.7083 9.87502 10.125C9.29169 9.54167 9.00002 8.83333 9.00002 8C9.00002 7.35 9.18752 6.77083 9.56252 6.2625C9.93752 5.75417 10.4167 5.4 11 5.2V4C11 3.71667 11.0959 3.47917 11.2875 3.2875C11.4792 3.09583 11.7167 3 12 3C12.2834 3 12.5209 3.09583 12.7125 3.2875C12.9042 3.47917 13 3.71667 13 4V5.2C13.5834 5.4 14.0625 5.75417 14.4375 6.2625C14.8125 6.77083 15 7.35 15 8C15 8.83333 14.7084 9.54167 14.125 10.125C13.5417 10.7083 12.8334 11 12 11ZM12 9C12.2834 9 12.5209 8.90417 12.7125 8.7125C12.9042 8.52083 13 8.28333 13 8C13 7.71667 12.9042 7.47917 12.7125 7.2875C12.5209 7.09583 12.2834 7 12 7C11.7167 7 11.4792 7.09583 11.2875 7.2875C11.0959 7.47917 11 7.71667 11 8C11 8.28333 11.0959 8.52083 11.2875 8.7125C11.4792 8.90417 11.7167 9 12 9ZM12.9 11.9C13.2334 11.8167 13.5459 11.6958 13.8375 11.5375C14.1292 11.3792 14.4 11.1833 14.65 10.95L17.45 18.7C17.4667 18.75 17.475 18.825 17.475 18.925L17.375 20C17.3417 20.2 17.2292 20.3375 17.0375 20.4125C16.8459 20.4875 16.675 20.45 16.525 20.3L15.725 19.525L15.6 19.35L12.9 11.9Z"/>
  </SvgIcon>
};

export default PatternIcon;