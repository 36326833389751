/* eslint-disable react-hooks/exhaustive-deps */
import { useApi } from '@backstage/core-plugin-api';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../apis/notificationApi';
import { toolsProvisioningApiRef } from '../../../apis/toolsProvisioningApi';
import { useEffect, useState } from 'react';

export const useGetServicesMapping = (
  apmNumber: string,
  shouldcall: boolean,
) => {
  const notificationApi: NotificationApi = useApi(notificationApiRef);
  const toolsprovisioningApi = useApi(toolsProvisioningApiRef);

  const [servicesMapping, setServicesMapping] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchMyAPI = async () => {
    setLoading(true);
    if (toolsprovisioningApi) {
      try {
        const response = await toolsprovisioningApi.getAllServicesMapping(
          apmNumber,
        );
        if (response) {
          setLoading(false);
          setServicesMapping(response);
        }
      } catch (error) {
        setLoading(false);
        notificationApi.sendNotification({
          message: `Error occurred - ${error}`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      }
    }
  };

  useEffect(() => {
    if (shouldcall) fetchMyAPI();
  }, [shouldcall]);

  return [servicesMapping, loading] as const;
};
