import React from 'react';
import { useStyles } from '../../styles';
import { Card, CardContent, CardHeader, Divider } from '@material-ui/core';
import { ListProps } from '../../interfaces/projectMappingInterfaces';
import { useFilteredProjectDetails } from '../../hooks/useFilteredProjectDetails';
import List from '../common/List';

const ListCard = ({
  cardTitle,
  toolName,
  subHeader,
  data,
  ActionComponent,
  FilterComponent,
}: ListProps) => {
  const classes = useStyles();

  // const { filteredProjectDetails } = useFilteredProjectDetails(
  //   data,
  //   chipLabels,
  // );

  const getAllItems = (data: any[]) => {
    const items: any[] = [];

    data.forEach(project => {
      // Add main project
      let projectorg = project?.projectorg;
      let projectname = project?.projectname;
      let urlPrefix =
        project?.projecturl &&
        project?.projecturl.split(`${project.projectorg}/`)[0];

      items.push({
        projectName: projectname || project,
        toolName: toolName,
        subHeader: subHeader,
        url: project?.projecturl,
        type: 'project',
      });

      // Add repos if they exist
      if (project.repos?.length > 0) {
        project.repos.forEach((repo: string) => {
          const repoName = repo.split('/')[1];
          items.push({
            projectName: repoName,
            toolName: projectname,
            subHeader: 'repository',
            url: `${urlPrefix}${projectorg}/${projectname}/_git/${repoName}`,
            type: 'repos',
          });
        });
      }

      // Add components if they exist
      if (project.components?.length > 0) {
        project.components.forEach((component: any) => {
          items.push({
            projectName: component.name,
            toolName: projectname,
            subHeader: 'component',
            type: 'components',
          });
        });
      }

      // Add areapaths if they exist
      if (project.areapaths?.length > 0) {
        project.areapaths.forEach((areapath: any) => {
          items.push({
            projectName: areapath,
            toolName: projectname,
            subHeader: 'areapath',
            type: 'areapaths',
          });
        });
      }
    });

    return items;
  };

  const getFilteredItems = (items: any[]) => {
    if (!FilterComponent?.selectedChip) return items;

    const selectedType = FilterComponent.selectedChip.label.toLowerCase();
    if (selectedType === 'all') return items;

    return items.filter(item => item.type === selectedType);
  };

  return (
    <>
      <Card classes={{ root: classes.height400 }}>
        <CardHeader
          title={cardTitle}
          classes={{
            root: classes.paddingBottom2,
            title: classes.cardHeaderTitle,
          }}
          action={ActionComponent}
        />
        <Divider />
        {FilterComponent?.component}
        <CardContent classes={{ root: classes.paddingControl }}>
          {data &&
            getFilteredItems(getAllItems(data)).map((item, index) => (
              <List
                key={index}
                projectName={item.projectName}
                toolName={item.toolName}
                subHeader={item.subHeader}
                url={item.url}
              />
            ))}
        </CardContent>
      </Card>
    </>
  );
};

export default ListCard;
