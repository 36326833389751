import React, { useState } from 'react';
import { Button, Menu, MenuItem } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useStyles } from '../../styles';
import AddExistingMapping from '../popups/AddExisting';

interface AddButtonProps {
  toolName: string;
  hasChildren: boolean;
}

const AddButton: React.FC<AddButtonProps> = ({ toolName, hasChildren }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuOptions, setMenuOptions] = useState<
    Array<{ label: string; onClick: () => void }>
  >([]);
  const [existingPopup, setExistingPopup] = useState({
    open: false,
    onClose: () => {},
    popupContent: {},
  });

  const handleCloseExistingPopup = () => {
    setExistingPopup(prevState => ({ ...prevState, open: false }));
  };

  const handleExistingRepoClick = () => {
    setExistingPopup({
      open: true,
      onClose: handleCloseExistingPopup,
      popupContent: {
        title: 'Link existing repo',
        textFieldLabel: 'Repo link',
      },
    });
  };

  const handleAddMenu = (event: React.MouseEvent<HTMLElement>) => {
    setMenuOptions([
      {
        label: 'Add Existing Repo',
        onClick: () => {
          handleExistingRepoClick();
          setAnchorEl(null);
        },
      },
    ]);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  if (!hasChildren || toolName?.toLowerCase() !== 'ado') return null;

  return (
    <>
      <Button
        className={classes.addButtonDetailsSection}
        endIcon={<ArrowDropDownIcon />}
        onClick={handleAddMenu}
      >
        Add
      </Button>
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleMenuClose}
      >
        {menuOptions.map((option, index) => (
          <MenuItem key={index} onClick={option.onClick}>
            {option.label}
          </MenuItem>
        ))}
      </Menu>
      {existingPopup?.open && <AddExistingMapping {...existingPopup} />}
    </>
  );
};

export default AddButton;
