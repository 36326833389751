import { Content, Header, Page, InfoCard } from '@backstage/core-components';
import { Button, Grid, LinearProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { JsonObject, JsonValue } from '@backstage/types';
import { FormValidation, IChangeEvent } from '@rjsf/core';
import qs from 'qs';
import { useStyles } from '../common/styles';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import useAsync from 'react-use/lib/useAsync';
import {
  scaffolderApiRef,
  CustomFieldValidator,
  FieldExtensionOptions,
} from '@backstage/plugin-scaffolder-react';
import { SecretsContextPoc } from '../SecretsContext';
import { MultistepJsonForm } from '../MultistepJsonForm';
import { ApiHolder, useApi, useApiHolder } from '@backstage/core-plugin-api';
import { stringifyEntityRef, Entity } from '@backstage/catalog-model';
import { catalogApiRef, EntityProvider } from '@backstage/plugin-catalog-react';
import { HelpComponent } from '../../utils/helpComponent';
import HELP_URL from '../../utils/helpLinkConstant';
import BackButton from '../../utils/backButton';
import { removeLineBreaks } from '../common/regex';

import {
  NotificationApi,
  notificationApiRef,
} from '../../../apis/notificationApi';
import { DevxBreadCrumb } from '../../common/BreadcrumbsNav/DevxBreadCrumb';

const useTemplateParameterSchema = (templateRef: string) => {
  const scaffolderApi = useApi(scaffolderApiRef);
  const { value, loading, error } = useAsync(
    () => scaffolderApi.getTemplateParameterSchema(templateRef),
    [scaffolderApi, templateRef],
  );
  return { schema: value, loading, error };
};

function isObject(obj: unknown): obj is JsonObject {
  return typeof obj === 'object' && obj !== null && !Array.isArray(obj);
}

export const createValidator = (
  rootSchema: JsonObject,
  validators: Record<string, undefined | CustomFieldValidator<unknown>>,
  context: {
    apiHolder: ApiHolder;
  },
) => {
  function validate(
    schema: JsonObject,
    formData: JsonObject,
    errors: FormValidation,
  ) {
    const schemaProps = schema.properties;
    if (!isObject(schemaProps)) {
      return;
    }

    for (const [key, propData] of Object.entries(formData)) {
      const propValidation = errors[key];

      if (isObject(propData)) {
        const propSchemaProps = schemaProps[key];
        if (isObject(propSchemaProps)) {
          validate(
            propSchemaProps,
            propData as JsonObject,
            propValidation as FormValidation,
          );
        }
      } else {
        const propSchema = schemaProps[key];
        const fieldName =
          isObject(propSchema) && (propSchema['ui:field'] as string);

        if (fieldName && typeof validators[fieldName] === 'function') {
          validators[fieldName]!(
            propData as JsonValue,
            propValidation,
            context,
          );
        }
      }
    }
  }

  return (formData: JsonObject, errors: FormValidation) => {
    validate(rootSchema, formData, errors);
    return errors;
  };
};

export const AddPoc = ({
  customFieldExtensions = [],
}: {
  customFieldExtensions?: FieldExtensionOptions<any, any>[];
}) => {
  const apiHolder = useApiHolder();
  const secretsContext = useContext(SecretsContextPoc);
  const [errortext, setErrortext] = useState('');
  const [errortextForSource, setErrortextForSource] = useState('');
  const [errorDesctext, setErrorDesctext] = useState('');
  const [errorNametext, setErrorNametext] = useState('');
  const catalogApi = useApi(catalogApiRef);
  const scaffolderApi = useApi(scaffolderApiRef);
  const classes = useStyles();
  const notificationApi: NotificationApi = useApi(notificationApiRef);
  const navigate = useNavigate();
  const { schema, loading, error } =
    useTemplateParameterSchema('poc-form-builder');
  const [formState, setFormState] = useState<Record<string, any>>(() => {
    const query = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });

    try {
      return JSON.parse(query.formData as string);
    } catch (e) {
      return query.formData ?? {};
    }
  });
  const handleFormReset = () => setFormState({});
  const handleChange = useCallback(
    (e: IChangeEvent) => {
      setFormState(e.formData);
      setErrortext('');
      setErrortextForSource('');
      setErrorDesctext('');
      setErrorNametext('');
    },

    [setFormState, secretsContext?.secrets?.checkHaveARepo],
  );
  const [entity, setEntity] = useState<Entity | undefined>();

  useEffect(() => {
    if (catalogApi) {
      catalogApi
        .getEntityByRef({
          kind: 'Template',
          name: 'poc-form-builder',
          namespace: 'default',
        })
        .then((value: Entity | undefined) => {
          setEntity(value);
        })
        .catch((err: any) => {
          notificationApi.sendNotification({
            message: `Error occurred - ${err?.message}`,
            disapperAfterMs: 2500,
            severity: 'error',
          });
        });
    }
  }, [catalogApi, schema]);

  const handleCreate = async () => {
    if (
      formState?.haveAPocRepo &&
      (!formState?.ProjectRepoUrlForClone?.trim() ||
        formState?.repoUrl === 'select?')
    ) {
      if (!formState?.ProjectRepoUrlForClone) {
        setErrortext(
          'Repo URL for Cloning is Missing. Press continue after completion.',
        );
      }
      if (formState?.repoUrl === 'select?') {
        setErrortextForSource('Source is Require');
      }
      return
    } else if (
      (formState?.haveAPocRepo &&
        !formState?.ProjectRepoUrlForClone?.includes('azure.com') &&
        formState?.repoUrl.includes('azure.com')) ||
      (formState?.haveAPocRepo &&
        !formState?.ProjectRepoUrlForClone?.includes('github.com') &&
        formState?.repoUrl?.includes('github.com'))
    ) {
      setErrortext(
        `Repo Url For Clone is not from ${formState?.repoUrl?.split('?')[0]}`,
      );
      
      return;
    }

    if (formState?.haveAPocRepo &&
      formState?.ProjectRepoUrlForClone?.includes('github.com') &&
      formState?.repoUrl?.includes('github.com') &&
      !!formState?.ProjectRepoUrlForClone && !!secretsContext?.secrets.orgs) {
      if (secretsContext?.secrets.orgs.length == 0 || !secretsContext?.secrets.orgs.find((org: any) => formState?.ProjectRepoUrlForClone.includes(org.login))) {
        setErrortextForSource('Please use a repo from a CBRE approved Github Org')
        return
      }
    }
    

    if (formState?.description?.length > 350 && formState?.name?.length > 99) {
      setErrorDesctext('Description allow 350 characters only');
      setErrorNametext('Name allow 100 characters only');
      return;
    }

    if (formState?.description?.length > 350) {
      setErrorDesctext('Description allow 350 characters only');
      return;
    }

    if (formState?.name?.length > 100) {
      setErrorNametext('Name allow 100 characters only');
      return;
    }

    const repoName = formState?.name?.trim()?.replaceAll(' ', '-');
    const orgName = secretsContext?.secrets['PUBLISHER_GIT_REPO_NAME'];
    const owner = secretsContext?.secrets['PUBLISHER_INFO'];
    const repoUrl = `github.com?owner=${orgName}&repo=POC_2025_${repoName}&organization=${orgName}`;
    const ProjectRepoUrl = formState?.haveAPocRepo
      ? formState?.ProjectRepoUrlForClone?.trim()
      : '/scaffold';

    const { taskId } = await scaffolderApi.scaffold({
      templateRef: stringifyEntityRef({
        name: 'poc-form-builder',
        kind: 'template',
        namespace: 'default',
      }),

      values: {
        ProjectRepoUrlForClone: ProjectRepoUrl,
        description: removeLineBreaks(formState?.description),
        loggedInUser: formState?.loggedInUser,
        name: repoName,
        repoUrl: repoUrl,
        tags: formState?.tags,
      },
      secrets: secretsContext?.secrets,
    });

    const formParams = qs.stringify(
      { formData: formState },
      { addQueryPrefix: true },
    );
    const newUrl = `${window.location.pathname}${formParams}`;
    window.history?.replaceState(null, document.title, newUrl);
    navigate(`/poc-hub/add-poc/tasks/${taskId}`, { state: formState });
  };

  if (error) {
    notificationApi.sendNotification({
      message: `Failed to load template, ${error}`,
      disapperAfterMs: 2500,
      severity: 'error',
    });
    return <Navigate to={'/poc-hub'} />;
  }
  if (!loading && !schema) {
    notificationApi.sendNotification({
      message: `Template was not found.`,
      disapperAfterMs: 2500,
      severity: 'error',
    });
    return <Navigate to={'/poc-hub'} />;
  }

  const customFieldComponents = Object.fromEntries(
    customFieldExtensions.map(({ name, component }) => [name, component]),
  );

  const customFieldValidators = Object.fromEntries(
    customFieldExtensions.map(({ name, validation }) => [name, validation]),
  );

  return (
    <Page themeId="home">
      <Header
        pageTitleOverride="Create a New POC"
        title="Create a New POC"
        subtitle={
          <DevxBreadCrumb
            routes={[
              {
                type: 'link',
                link: '/',
                text: 'Home',
              },
              {
                type: 'link',
                link: '/poc-hub',
                text: 'POC Hub',
              },
              {
                type: 'text',
                link: '',
                text: 'Add POC',
              },
            ]}
          />
        }
      />
      <Content>
        {loading && <LinearProgress data-testid="loading-progress" />}
        <Grid container className={classes.addPocContainer}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <BackButton />
            <HelpComponent helpUrl={HELP_URL.ADD_POC_PAGE} />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            {schema && (
              <InfoCard className={classes.chatbotMargin} noPadding>
                <Grid item xs={12}>
                  <Alert severity="info">
                    <span className={classes.bold}>Here are some easy steps to add to the catalog.</span><br />
                    <span className={classes.spacedCharacter}>1.</span>Fill in your “Team Name” as the “Name”  and add a summarized  description.<br />
                    <span className={classes.spacedCharacter}>2.</span>Add tags starting with  “Innovate 2023” and other AI based tags you feel appropriate or add your own.<br />
                    <span className={classes.spacedCharacter}>3.</span>If you have a code repo with your code then click on the checkbox and give the information regarding your repo(Github/Azure, repo name…) otherwise just click on “Continue” to have your own repo created which can be used to add your code or a placeholder for you to add to later.<br/>
                    <span className={classes.spacedCharacter}>4.</span>On the additional details page, you can add a demo link, thumbnail, or documents like powerpoint, word, .. that you wish to upload directly.  Code can not be directly uploaded, please refer to step 4 instructions.<br/>
                    <span className={classes.spacedCharacter}>5.</span>Afterward click on submit.
                  </Alert>
                </Grid>
                <EntityProvider entity={entity}>
                  <MultistepJsonForm
                    errortextForSource={errortextForSource}
                    errortext={errortext}
                    errorDesctext={errorDesctext}
                    errorNametext={errorNametext}
                    formData={formState}
                    fields={customFieldComponents}
                    onChange={handleChange}
                    onReset={handleFormReset}
                    onFinish={handleCreate}
                    customFieldExtensions={customFieldExtensions}
                    steps={schema.steps.map(step => {
                      return {
                        ...step,
                        validate: createValidator(
                          step.schema,
                          customFieldValidators,
                          { apiHolder },
                        ),
                      };
                    })}
                  />
                </EntityProvider>
              </InfoCard>
            )}
          </Grid>
        </Grid>
      </Content>
    </Page>
  );
};
