import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { commonStyles } from '../../../styles/common';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...commonStyles(theme),
    /* Common styles */
    labelAndValue: {
      display: 'flex',
      color: theme.palette.text.secondary,
      margin: `${theme.spacing(0.625)}px 0px`,
      gap: `${theme.spacing(2)}px`,
    },
    promptDetailBox: {
      maxHeight: `${theme.spacing(75.5)}px`,
      borderRadius: `${theme.spacing(2)}px`,
    },
    popupTitle: {
      display: 'flex',
      justifyContent: 'space-between',
      color: theme.palette.text.secondary,
    },
    sectionContainer: {
      background: theme.palette.background.tertiary,
      padding: `${theme.spacing(1.5)}px`,
      marginBottom: `${theme.spacing(2)}px`,
      borderRadius: `${theme.spacing(0.5)}px`,
    },
    errorContainer: {
      background: theme.palette.errorBackground,
      padding: `${theme.spacing(1.5)}px`,
      marginBottom: `${theme.spacing(2)}px`,
      borderRadius: `${theme.spacing(0.5)}px`,
    },
    sectionHeading: {
      color: theme.palette.text.secondary,
      fontWeight: 600,
      marginBottom: `${theme.spacing(1)}px`,
      lineHeight: `${theme.spacing(2.5)}px`,
    },
    errorHeading: {
      color: theme.palette.errorText,
      fontWeight: 600,
      display: 'flex',
      alignItems: 'center',
      gap: `${theme.spacing(1.25)}px`,
    },
    errorMessage: {
      color: theme.palette.text.secondary,
      padding: `${theme.spacing(1.5)}px 0px`,
      fontSize: `${theme.spacing(2)}px`,
    },
    editConfigBtnContainer: {
      display: 'flex',
      justifyContent: 'right',
    },
    editConfigButton: {
      color: theme.palette.text.primary,
      backgroundColor: 'rgb(0, 63, 45, 0.08)',
      textTransform: 'none',
      boxShadow: 'none',
      fontWeight: 'bold',
      '&:hover': {
        color: theme.palette.text.primary,
        backgroundColor: 'rgb(0, 63, 45, 0.08)',
      },
    },
    label: {
      minWidth: `${theme.spacing(19.625)}px`,
      color: theme.palette.text.tertiary,
    },

    /* Error info popup styles */
    helpInfo: {
      padding: `0px ${theme.spacing(1.25)}px ${theme.spacing(1.25)}px 0px`,
      display: 'flex',
      gap: `${theme.spacing(1)}px`,
    },
    infoPopup: {
      color: theme.palette.text.secondary,
      '& a': {
        color: theme.palette.alert.link,
      },
    },
    infoMessage: {
      fontSize: `${theme.spacing(2)}px`,
    },

    // AddExistingMapping popup styles

    addExistingPopup: {
      minWidth: `${theme.spacing(70)}px`,
    },
    contentFlex: {
      display: 'flex',
      flexDirection: 'column',
      gap: `${theme.spacing(2)}px`,
    },
  }),
);
