import React, { useEffect, useState } from 'react';
import { Content, PageWithHeader } from '@backstage/core-components';
import { Button, Grid, MenuItem, Select } from '@material-ui/core';
import CustomizedTimeline from './roadmapv2';
import { roadmapApiRef } from '../../apis/roadmapApi';
import { useApi } from '@backstage/core-plugin-api';

const Roadmap: React.FC = () => {
  const [timelineData, setTimelineData] = useState<any[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [view, setView] = useState<'table' | 'graph'>('graph');
  const [filters, setFilters] = useState<{ field: string; value: string[] }[]>([{ field: '', value: [] }]);

  const roadmapApi = useApi(roadmapApiRef);

  useEffect(() => {
    const fetchData = async () => {
      const data = await roadmapApi.getRoadmapFile();
      if (data) {
        console.log(data);
        const d = JSON.parse(data.content.replace('\\',''));
        console.log(d)
        setTimelineData(d);
        setFilteredData(d);
      } else {
        setTimelineData([]);
        setFilteredData([]);
      }
    };

    fetchData();
  }, [roadmapApi]);


  const toggleView = () => {
    setView((prevView) => (prevView === 'table' ? 'graph' : 'table'));
  };

  const handleFieldChange = (index: number, event: React.ChangeEvent<HTMLSelectElement>) => {
    const newFilters = [...filters];
    newFilters[index].field = event.target.value;
    newFilters[index].value = [];
    setFilters(newFilters);
    applyFilters(newFilters);
  };

  const handleValueChange = (index: number, event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as string[];
    const newFilters = [...filters];
    newFilters[index].value = value;
    setFilters(newFilters);
    applyFilters(newFilters);
  };

  const applyFilters = (filters: { field: string; value: string[] }[]) => {
    let filtered = timelineData;
    filters.forEach(filter => {
      if (filter.field && filter.value.length > 0) {
        filtered = filtered.filter(item => filter.value.includes(item[filter.field]));
      }
    });
    setFilteredData(filtered);
  };

  const getUniqueValues = (field: string) => {
    return Array.from(new Set(timelineData.map(item => item[field]))).filter(Boolean);
  };

  const addFilter = () => {
    setFilters([...filters, { field: '', value: [] }]);
  };

  const removeFilter = (index: number) => {
    const newFilters = filters.filter((_, i) => i !== index);
    setFilters(newFilters);
    applyFilters(newFilters);
  };

  return (
    <PageWithHeader
      themeId="home"
      pageTitleOverride='Roadmap'
      title={undefined}
    >
      <Content>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Grid xs={12}>
            <Button variant="contained" color="primary" onClick={toggleView}>
              Toggle to {view === 'table' ? 'Graph' : 'Table'} View
            </Button>
          </Grid>
          {filters.map((filter, index) => (
            <Grid xs={12} style={{ margin: '10px 0', display: 'flex', alignItems: 'center' }} key={index}>
              <Select value={filter.field} onChange={(e) => handleFieldChange(index, e)} style={{ width: '200px' }} >
                <MenuItem value="Category">Category</MenuItem>
                <MenuItem value="Project">Project</MenuItem>
                <MenuItem value="Component/Tool">Component/Tool</MenuItem>
                <MenuItem value="Status">Status</MenuItem>
              </Select>
              {filter.field && (
                <Select
                  value={filter.value}
                  onChange={(e) => handleValueChange(index, e)}
                  multiple
                  style={{ marginLeft: '10px', width: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                >
                  {getUniqueValues(filter.field).map((value, index) => (
                    <MenuItem key={index} value={value}>{value}</MenuItem>
                  ))}
                </Select>
              )}
              <Button variant="contained" color="secondary" onClick={() => removeFilter(index)} style={{ marginLeft: '10px' }}>
                Remove
              </Button>
            </Grid>
          ))}
          <Grid xs={12} style={{ margin: '10px 0' }}>
            <Button variant="contained" color="secondary" onClick={addFilter}>
              Add Filter
            </Button>
          </Grid>
          <Grid container xs={12}>
            {view === 'table' ? (
              <div style={{ width: '100%', textAlign: 'center' }}>
              {['Q1', 'Q2', 'Q3', 'Q4'].map((quarter) => (
                <div key={quarter} style={{ marginBottom: '20px' }}>
                <h3 style={{ textAlign: 'center' }}>{quarter}</h3>
                <table style={{ width: '100%', borderCollapse: 'collapse', margin: '0 auto' }}>
                  <thead>
                  <tr>
                    <th style={{ border: '1px solid #ddd', padding: '8px' }}>Category</th>
                    <th style={{ border: '1px solid #ddd', padding: '8px' }}>Project</th>
                    <th style={{ border: '1px solid #ddd', padding: '8px' }}>Component/Tool</th>
                    <th style={{ border: '1px solid #ddd', padding: '8px', width: '50%' }}>Feature</th>
                    <th style={{ border: '1px solid #ddd', padding: '8px' }}>Status</th>
                  </tr>
                  </thead>
                  <tbody>
                  {filteredData
                    .filter((item) => item.TargetQuarter === quarter)
                    .map((item, index) => (
                    <tr key={index}>
                      <td style={{ border: '1px solid #ddd', padding: '8px' }}>{item.Category}</td>
                      <td style={{ border: '1px solid #ddd', padding: '8px' }}>{item.Project}</td>
                      <td style={{ border: '1px solid #ddd', padding: '8px' }}>{item['Component/Tool']}</td>
                      <td style={{ border: '1px solid #ddd', padding: '8px', width: '50%' }}>{item.Feature}</td>
                      <td style={{ border: '1px solid #ddd', padding: '8px' }}>{item.Status}</td>
                    </tr>
                    ))}
                  </tbody>
                </table>
                </div>
              ))}
              </div>
            ) : (
              <div>
              <CustomizedTimeline data={filteredData} />
              </div>
            )}
          </Grid>
        </div>
      </Content>
    </PageWithHeader>
  );
};

export default Roadmap;
