import React, { useState } from 'react';
import {
  TableColumn,
  Table,
  WarningPanel,
  CodeSnippet,
  TableProps,
} from '@backstage/core-components';
import {
  humanizeEntityRef,
  getEntityRelations,
} from '@backstage/plugin-catalog-react';
import * as columnFactories from '../../../utils/devXTableColumns';
import * as constant from '../../../utils/constant';
import {
  RELATION_OWNED_BY,
  RELATION_PART_OF,
  DEFAULT_NAMESPACE,
} from '@backstage/catalog-model';
import {
  Button as MaterialButton,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { generatePath } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    themeColor: {
      '& tr': {
        backgroundColor: theme.palette.background.paper,
        borderBottomColor: theme.palette.background.default + '!important',
        '& td ': {
          backgroundColor: theme.palette.background.paper,
          borderBottomColor: theme.palette.background.default + '!important',
        },
        '& th': {
          backgroundColor: theme.palette.background.paper,
          borderBottomColor: theme.palette.background.default + '!important',
        },
      },
      '& div::-webkit-scrollbar-thumb': {
        border: `4px solid ${theme.palette.text.tertiary}`,
      },
    },
  }),
);

export const ReviewTable = ({
  loading,
  error,
  entities,
  checkFilterItem,
  starredFilterItem,
  reviewerList,
}: any) => {
  const columns: TableColumn<columnFactories.EntityRow>[] = [
    constant.IS_ADD_Reviewer_IN_ASSETS
      ? columnFactories.createAssignedTo(reviewerList)
      : columnFactories.createMetadataDescriptionColumn(),
    columnFactories.createNameColumnCustomRoot({ rootRoute: 'catalog' }),
    columnFactories.createOwnerColumn(),
    columnFactories.createSpecTypeColumn(),
    // columnFactories.createMetadataDescriptionColumn(),
    columnFactories.createTagsColumn(),
    columnFactories.createDateColumn(),
    columnFactories.createStatusColumn(),
    //columnFactories.createRatingColumn(),
  ];

  let tableTitle = 'All';
  const [sortModel, setSortModel] = useState([
    {
      field: 'Assets',
      sort: 'asc',
    },
  ]);
  const classes = useStyles();
  const defaultActions: TableProps<columnFactories.EntityRow>['actions'] = [
    ({ entity }) => {
      return {
        icon: () => (
          <MaterialButton
            href={generatePath(
              `/catalog/${DEFAULT_NAMESPACE}/${entity.kind}/${entity.metadata.name}/reviewAndComments`,
            )}
            color="primary"
            variant="contained"
          >
            Review
          </MaterialButton>
        ),
        onClick: () => localStorage.setItem('isReview', 'true'),
      };
    },
  ];

  let filterData = (entities && entities.length && entities) || [];

  if (checkFilterItem.isCodeFilterClick) {
    tableTitle = 'Code';
    filterData =
      entities &&
      entities.filter(
        (item: any) =>
          item.kind?.toLowerCase() === constant.COMPONENT?.toLowerCase() &&
          item?.spec?.type?.toLowerCase() !==
            constant.IAC_COMPONENT_TYPE?.toLowerCase() &&
          item?.spec?.type?.toLowerCase() !==
            constant.SALESFORCE_COMPONENT_TYPE?.toLowerCase(),
      );
  }

  if (checkFilterItem.isApiFilterClick) {
    tableTitle = 'API';
    filterData =
      entities &&
      entities.filter(
        (item: any) => item.kind?.toLowerCase() === constant.API?.toLowerCase(),
      );
  }

  if (checkFilterItem.isFavoriteFilterClick) {
    tableTitle = 'Bookmark';
    filterData = starredFilterItem;
  }

  if (checkFilterItem.isIacFilterClick) {
    tableTitle = 'Infrastructure';
    filterData =
      entities &&
      entities.filter(
        (item: any) =>
          item.kind?.toLowerCase() === constant.COMPONENT?.toLowerCase() &&
          item?.spec?.type?.toLowerCase() ===
            constant.IAC_COMPONENT_TYPE?.toLowerCase(),
      );
  }
  if (checkFilterItem.isSalesforceFilterClick) {
    tableTitle = 'Salesforce';
    filterData =
      entities &&
      entities.filter(
        (item: any) =>
          item.kind?.toLowerCase() === constant.COMPONENT?.toLowerCase() &&
          item?.spec?.type?.toLowerCase() ===
            constant.SALESFORCE_COMPONENT_TYPE?.toLowerCase(),
      );
  }
  if (checkFilterItem.isAIFilterClick) {
    tableTitle = 'AI Accelerators';
    filterData =
      entities &&
      entities.filter(
        (item: any) =>
          item.kind?.toLowerCase() === constant.COMPONENT?.toLowerCase() &&
          item?.spec?.type?.toLowerCase() ===
            constant.AI_COMPONENT_TYPE?.toLowerCase(),
      );
  }
  if (checkFilterItem.isExtensionFilterClick) {
    tableTitle = 'Extension';
    filterData =
      entities &&
      entities.filter(
        (item: any) =>
          item.kind?.toLowerCase() === constant.COMPONENT?.toLowerCase() &&
          item?.spec?.type?.toLowerCase() ===
            constant.EXTENSION_COMPONENT_TYPE?.toLowerCase(),
      );
  }
  if (checkFilterItem.isPatternFilterClick) {
    tableTitle = 'Architecture Patterns'
    filterData = 
      entities && 
      entities.filter(
        (item: any) =>
          item.kind?.toLowerCase() === constant.COMPONENT?.toLowerCase() &&
          item?.spec?.type?.toLowerCase() ===
            constant.PATTERN_COMPONENT_TYPE?.toLowerCase(),
      );
  }

  let rows = filterData.map((entity: any) => {
    const partOfSystemRelations = getEntityRelations(entity, RELATION_PART_OF, {
      kind: 'system',
    });
    const ownedByRelations = getEntityRelations(entity, RELATION_OWNED_BY);

    return {
      entity,
      resolved: {
        name: humanizeEntityRef(entity, {
          defaultKind: 'Component',
        }),
        ownedByRelationsTitle: ownedByRelations
          .map(r => humanizeEntityRef(r, { defaultKind: 'group' }))
          .join(', '),
        ownedByRelations,
        partOfSystemRelationTitle: partOfSystemRelations
          .map(r =>
            humanizeEntityRef(r, {
              defaultKind: 'system',
            }),
          )
          .join(', '),
        partOfSystemRelations,
      },
    };
  });

  // rows =
  //   rows &&
  //   rows.sort((x: any, y: any) => {
  //     const a =new Date(y?.metadata?.creationTimestamp);
  //     const b =new Date(x?.metadata?.creationTimestamp);
  //     return  a - b;
  //   });

  if (error) {
    return (
      <div>
        <WarningPanel
          severity="error"
          title="Could not fetch catalog entities."
        >
          <CodeSnippet language="text" text={error.toString()} />
        </WarningPanel>
      </div>
    );
  }
  // const titlePreamble = filters.user?.value ?? 'owned';

  return (
    <div className={`mystuff-table reviewer-table ${classes.themeColor}`}>
      <Table
        isLoading={loading}
        columns={columns}
        options={{
          paging: true,
          pageSize: 20,
          search: true,
          actionsColumnIndex: 0,
          loadingType: 'linear',
          showEmptyDataSourceMessage: !loading,
          padding: 'dense',
          pageSizeOptions: [20],
        }}
        sortModel={sortModel}
        data={rows || []}
        actions={defaultActions}
        onSortModelChange={model => setSortModel(model)}
        title={`${tableTitle} (${rows && rows.length})`}
      />
    </div>
  );
};
