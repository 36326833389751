import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from '@material-ui/core';
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { useStyles } from '../../styles/popupStyles';

const AddExistingMapping = ({ open, onClose, popupContent }: any) => {
  const classes = useStyles();

  return (
    <Dialog open={open} classes={{ paper: classes.addExistingPopup }}>
      <div className={classes.popupTitle}>
        <DialogTitle>
          <span>{popupContent.title}</span>
        </DialogTitle>
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent dividers className={classes.contentFlex}>
        <div>Please fill the details:</div>
        <TextField
          required
          label={popupContent.textFieldLabel}
          variant="outlined"
          fullWidth
        />
      </DialogContent>
      <DialogActions classes={{ root: classes.padding2 }}>
        <Button
          variant="text"
          onClick={onClose}
          className={classes.sentenceCaseText}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={onClose}
          className={`${classes.sentenceCaseText} ${classes.buttonNoShadow}`}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddExistingMapping;
