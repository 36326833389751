import React, { useEffect, useState } from 'react';
import { Chip, CardActions } from '@material-ui/core';
import { useStyles } from '../../styles';
import { capitalizeFirstLetter } from '../services/utils';

interface ChipsSectionProps {
  data: any[];
}

const ChipsFilter: React.FC<ChipsSectionProps> = ({ data }) => {
  const classes = useStyles();
  const [chipLabels, setChipLabels] = useState<
    { label: string; selected: boolean }[]
  >([]);
  const [hasChildren, setHasChildren] = useState(false);
  const [selectedChip, setSelectedChip] = useState({
    label: 'All',
    selected: true,
  });

  useEffect(() => {
    const labels = [{ label: 'All', selected: true }];
    const arraysToCheck = ['repos', 'components', 'areapaths'];

    let hasChildrenFlag = false;

    arraysToCheck.forEach((arrayName: string) => {
      if (
        data?.some(
          (item: any) => item[arrayName] && item[arrayName]?.length > 0,
        )
      ) {
        labels.push({
          label: capitalizeFirstLetter(arrayName),
          selected: false,
        });
        hasChildrenFlag = true;
      }
    });

    setChipLabels(labels);
    setHasChildren(hasChildrenFlag);
  }, [data]);

  const handleChipClicked = (chipLabel: string) => {
    const updatedChips = chipLabels.map(chip =>
      chip.label === chipLabel
        ? { ...chip, selected: true }
        : { ...chip, selected: false },
    );
    setChipLabels(updatedChips);
    const selected = updatedChips.find(chip => chip.selected);
    if (selected) {
      setSelectedChip(selected);
    }
  };

  if (!hasChildren) return null;

  return {
    selectedChip,
    component: (
      <CardActions classes={{ root: classes.childChips }}>
        <div className={classes.marginLeftSmall}>
          {chipLabels.map((chip, index) => (
            <Chip
              key={index}
              size="small"
              label={chip.label}
              onClick={() => handleChipClicked(chip.label)}
              className={chip.selected ? classes.chipSelected : ''}
              classes={{ root: classes.marginBottom0 }}
            />
          ))}
        </div>
      </CardActions>
    ),
  };
  // return selectedChip.label;
};

export default ChipsFilter;
